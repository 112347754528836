// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

// TODO merge with scenesReducer
export const initialState = {
  providers: [],
  loading: false,
  error: null,
};

export const providersReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_START":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOAD_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "LOAD_PROVIDERS":
      return {
        ...state,
        providers: action.payload,
        loading: false,
        error: null,
      };
    case "ADD_PROVIDER":
      return {
        ...state,
        providers: [...state.providers, action.payload],
        loading: false,
        error: null,
      };
    case "UPDATE_PROVIDER":
      return {
        ...state,
        providers: state.providers.map((item) =>
          item.provider_id === action.payload.provider_id
            ? action.payload
            : item,
        ),
        loading: false,
        error: null,
      };
    case "DELETE_PROVIDER":
      return {
        ...state,
        providers: state.providers.filter(
          (item) => item.provider_id !== action.payload.provider_id,
        ),
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
