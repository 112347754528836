// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useRef, useEffect, useMemo } from "react";

import { useProviders } from "../../../context/providersContext";
import { useStudios } from "../../../context/studiosContext";

import { Card } from "../../../../web-utils/src/UI/Component/React/V1/Card/Card";

import { DownloadStatus } from "../DownloadStatus/DownloadStatus";
import { CardFooter } from "../CardFooter/CardFooter";
import { CardNav } from "../CardNav/CardNav";

import styles from "./Item.module.css";

export const Item = ({ item, handleSelect, selectedScene }) => {
  const { providers } = useProviders();
  const { studios } = useStudios();
  const { studio } = studios;
  const image = useMemo(() => {
    return item?.thumbnail
      ? `data:;base64,${item?.thumbnail}`
      : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
  }, [item?.thumbnail]);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const cardRef = useRef(null);

  const handleClickOutside = (event) => {
    if (cardRef.current && !cardRef.current.contains(event.target)) {
      setIsNavOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const providerItem = useMemo(
    () =>
      providers?.providers.find((el) => el.provider_id === item.provider_id),
    [providers, item],
  );

  const sceneStatus = useMemo(() => {
    if (!studio) return;
    return studio?.studio_scenes.find((el) => el.mosys_id === item.mosys_id);
  }, [studio, item]);

  return (
    <div className={styles.container} ref={cardRef}>
      <Card
        onClick={() => handleSelect(item)}
        classname={
          selectedScene?.mosys_id === item.mosys_id ? styles.active : ""
        }
        title={item.name}
        description={providerItem?.name}
        imageUrl={image}
        contentFooter={
          <CardFooter
            itemKeywords={item?.keywords}
            isNavOpen={isNavOpen}
            setIsNavOpen={setIsNavOpen}
          />
        }
        status={!sceneStatus ? null : <DownloadStatus scene={sceneStatus} />}
      />
      {isNavOpen && <CardNav item={item} />}
    </div>
  );
};
