import React from "react";

import MosysLogo from "../../../../../../shared/img/MoSysLogo.svg";

import styles from "./navBar.module.css";

export const NavBar = ({ navigation }) => {
  return (
    <nav className={styles.navbar}>
      <div className={styles.navbar_brand}>
        <MosysLogo />
      </div>
      {navigation}
    </nav>
  );
};
