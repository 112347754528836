import React from "react";

export const Logout = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M28.93,45.65H5.56c-1.66,0-3-1.34-3-3V5.35c0-1.66,1.34-3,3-3h23.37" />
      <line x1="21.8" y1="23.9" x2="45.97" y2="23.95" />
      <polygon points="42.65 28.03 41.97 27.3 45.59 23.95 41.98 20.59 42.67 19.86 47.06 23.96 42.65 28.03" />
    </svg>
  );
};
