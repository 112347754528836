// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
// TODO move component to submodule

import React from "react";

export const Avatar = ({ size = 48, firstName, lastName }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="48" cy="48" r="48" fill={`var(--color-light)`} />
      <text
        x="48"
        y="48"
        textAnchor="middle"
        alignmentBaseline="middle"
        fill={`var(--color-secondary)`}
        fontSize="45"
        dy=".15em"
      >
        {firstName && firstName.slice(0, 1).toUpperCase()}
        {lastName && lastName.slice(0, 1).toUpperCase()}
      </text>
    </svg>
  );
};
