import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import styles from "./Range.module.css";

export const Range = ({ name, value, ...props }) => {
  const { min, max, step = "1", disabled, onChange } = props;
  const { control, setValue } = useFormContext();

  const handleChange = (e) => {
    setValue(name, e.target.value);
    onChange(name, e.target.value);
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <input
          type="range"
          name={field.name}
          id={field.name}
          value={field.value}
          onChange={handleChange}
          min={min}
          max={max}
          step={step}
          disabled={disabled}
          className={styles.input_range}
        />
      )}
    />
  );
};
