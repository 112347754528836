import React from "react";

export const Offsets = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <line x1="22" y1="42.02" x2="45.14" y2="42.02" />
      <polygon points="42.48 46 41.94 45.29 44.82 42.02 41.94 38.75 42.48 38.04 46 42.02 42.48 46" />
      <line x1="28.7" y1="19.3" x2="45.06" y2="2.94" />
      <polygon points="46 7.63 45.11 7.51 44.84 3.16 40.49 2.89 40.37 2 45.67 2.33 46 7.63" />
      <line x1="5.42" y1="26" x2="5.42" y2="2.86" />
      <polygon points="9.4 5.52 8.69 6.06 5.42 3.18 2.15 6.06 1.44 5.52 5.42 2 9.4 5.52" />
      <rect
        x="3.34"
        y="29.31"
        width="19.13"
        height="11.6"
        rx=".52"
        ry=".52"
        transform="translate(-20.74 18.76) rotate(-43.91)"
      />
      <polygon points="18.63 27.01 21.5 22.1 26.2 27.02 21.19 29.7 18.63 27.01" />
      <rect
        x="5.44"
        y="36.89"
        width="8.52"
        height="2.09"
        transform="translate(-23.59 17.33) rotate(-43.91)"
      />
    </svg>
  );
};
