// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { useTheme } from "../../../context/themeContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { LinkComponent } from "../../../../web-utils/src/UI/Component/React/V1/LinkComponent/LinkComponent";
import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { DropdownNav } from "../DropdownNav/DropdownNav";
import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";
import { SearchBar } from "../Search/Search";

import styles from "./Sidebar.module.css";

export const type = ["2D", "2.5D", "Splat", "3D"];
const status = [
  "undownloaded",
  "downloading",
  "downloaded",
  "loading",
  "loaded",
];

export const Sidebar = () => {
  const { isSidebarOpen, setIsSidebarOpen } = useTheme();
  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <div
      className={styles.sidebar}
      onMouseEnter={() => setIsSidebarOpen(true)}
      onMouseLeave={() => setIsSidebarOpen(false)}
    >
      <div className={`${styles.toggle} ${isSidebarOpen && styles.toggleEnd}`}>
        <Button
          color="light"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          iconType={isSidebarOpen ? "control" : "controlback"}
        />
      </div>
      <div>
        {!isSidebarOpen && !isMobile ? (
          <div className={styles.search}>
            <Icons iconType="search" />
          </div>
        ) : (
          <div
            className={`${styles.searchForm}  ${isSidebarOpen ? styles.show : styles.hide}`}
          >
            <SearchBar />
          </div>
        )}
      </div>
      <ul className={styles.navbar}>
        <DropdownNav
          title="Explore"
          iconType="explore"
          isSidebarOpen={isSidebarOpen}
        >
          <DropdownNav
            title="By type"
            isSidebarOpen={isSidebarOpen}
            openOnClick
          >
            <li>
              <LinkComponent href={`/`} sidebar>
                <span className={styles.title}>All</span>
              </LinkComponent>
            </li>
            {type.map((el, index) => (
              <li key={index}>
                <LinkComponent href={`provider/${el}`} sidebar>
                  <span className={styles.title}>{el}</span>
                </LinkComponent>
              </li>
            ))}
          </DropdownNav>
          <DropdownNav
            title="By status"
            isSidebarOpen={isSidebarOpen}
            openOnClick
          >
            {status.map((el, index) => (
              <li key={index}>
                <LinkComponent href={`scene/${el}`} sidebar>
                  <span className={styles.title}>{el}</span>
                </LinkComponent>
              </li>
            ))}
          </DropdownNav>
        </DropdownNav>
        <DropdownNav
          title="Modify"
          href="/modify"
          iconType="edit"
          isSidebarOpen={isSidebarOpen}
        >
          <p className={styles.title}>Coming soon</p>
        </DropdownNav>
        <DropdownNav
          title="Studios"
          href="/studios"
          iconType="settings"
          isSidebarOpen={isSidebarOpen}
        >
          <p className={styles.title}>Coming soon</p>
        </DropdownNav>
      </ul>
    </div>
  );
};
