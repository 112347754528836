import React from "react";

export const Feedback = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M46,11.34v22.12c0,1.75-1.48,3.16-3.3,3.16H8.72c-.88,0-1.72.41-2.29,1.11l-4.39,5.44-.02-9.13V11.34c0-1.75,1.48-3.16,3.3-3.16h37.38c1.82,0,3.3,1.42,3.3,3.16Z" />
      <line x1="23.98" y1="30.95" x2="24" y2="20.2" strokeLinecap="round" />
      <path
        d="M24.5,15.06c0-.28-.22-.5-.5-.5s-.5.22-.5.5.22.5.5.5.5-.22.5-.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
