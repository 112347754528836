import React from "react";

export const Network = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M43.2,20.89v14.36c0,1.1-.9,2-2,2H6.84c-1,0-1.82-.82-1.82-1.82v-14.55h38.18M44.11,19.98H4.11v15.45c0,1.51,1.22,2.73,2.73,2.73h34.36c1.61,0,2.91-1.3,2.91-2.91v-15.27h0Z" />
      <path d="M12.69,4.83c2.16-1.28,5.3-2.99,11.35-3.11,6.06-.02,11.74,2.4,15.9,6.48" />
      <path d="M12.58,4.89c-1.56.93-3,2.03-4.3,3.3" />
      <path d="M15.86,9c1.46-.86,3.85-2.38,8.29-2.39,4.71.02,9.09,2.18,11.98,5.73" />
      <path d="M15.74,9.07c-1.38.88-2.62,1.98-3.67,3.25" />
      <path d="M18.94,13.31c1.24-.98,2.6-1.97,5.17-1.94,4.65.02,7.31,4.26,7.61,4.74" />
      <path d="M18.95,13.31c-1.49,1.2-2.34,2.54-2.5,2.8" />
      <path d="M27.22,43.5c0,.1,0,.19-.02.28-.13,1.68-1.51,3-3.2,3s-3.07-1.32-3.2-3c-.02-.09-.02-.18-.02-.28,0-1.81,1.44-3.28,3.22-3.28s3.22,1.47,3.22,3.28Z" />
      <line x1="44" y1="43.78" x2="27.2" y2="43.78" />
      <line x1="20.8" y1="43.78" x2="4" y2="43.78" />
      <line x1="23.91" y1="37.82" x2="23.91" y2="40.57" />
    </svg>
  );
};
