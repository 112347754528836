import React from "react";

export const Link = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <rect
        x="1.78"
        y="11.94"
        width="44.43"
        height="24.12"
        rx="12.06"
        ry="12.06"
      />
      <line x1="14.55" y1="24.01" x2="33.45" y2="23.99" />
    </svg>
  );
};
