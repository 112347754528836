import React from "react";

export const Explore = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M24,4.91c10.53,0,19.09,8.56,19.09,19.09s-8.56,19.09-19.09,19.09S4.91,34.53,4.91,24,13.47,4.91,24,4.91M24,4C12.95,4,4,12.95,4,24s8.95,20,20,20,20-8.95,20-20S35.05,4,24,4h0Z" />
      <path d="M12.23,35l8.32-14.4c.25-.43.61-.77,1.05-.99l13.93-6.93c.18-.09.36.1.26.28l-7.63,13.72c-.24.43-.6.78-1.03,1l-14.64,7.59c-.18.09-.37-.1-.27-.28Z" />
      <circle cx="24" cy="24" r="2.21" />
    </svg>
  );
};
