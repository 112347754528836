// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useMemo } from "react";

import { useStudios } from "../../../context/studiosContext";
import { useWebsocket } from "../../../context/websocketContext/websocketContext";

import { Alert } from "../../../../web-utils/src/UI/Component/React/V1/Alert/Alert";
import { Scroll } from "../../../../web-utils/src/UI/Component/React/V1/Scroll/Scroll";
import { Action } from "../Action/Action";
import { Badge } from "../../../../web-utils/src/UI/Component/React/V1/Badge/Badge";
import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { withToolTip } from "../../../../web-utils/src/UI/Component/React/V1/Hooks/withToolTip/withToolTip";

import styles from "./StudioInfo.module.css";

const BadgeComponent = withToolTip(Badge);

export const StudioInfo = ({ scene, provider }) => {
  const { studios, dispatch } = useStudios();
  const { studio, error } = studios;
  const { connectWebSocket } = useWebsocket();

  const sceneInStudio = useMemo(
    () => studio?.studio_scenes.find((el) => el.mosys_id === scene?.mosys_id),
    [studio, scene],
  );

  const handleReconnect = async () => {
    dispatch({ type: "CLEAR_ERROR" });
    connectWebSocket();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h3>Studio</h3>
        {error && (
          <Scroll height="60px">
            <div className={styles.wrapper}>
              <Alert variant="error" message={error} />
            </div>
          </Scroll>
        )}
        <div className={styles.status}>
          <p>{studio?.name}</p>
          {studio.connected ? (
            <BadgeComponent
              variant="success"
              round={true}
              tooltipText="Connected"
              position="left"
            />
          ) : (
            <Button onClick={handleReconnect}>Connect</Button>
          )}
        </div>
        <p>Unreal version {studio?.unreal_version?.version}</p>
        {!sceneInStudio ? null : (
          <div className={styles.status}>
            <p>Scene status </p>
            <p className={styles.small}>{sceneInStudio?.status}</p>
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <Action
          scene={scene}
          sceneInStudio={sceneInStudio}
          provider={provider}
        />
      </div>
    </div>
  );
};
