import React from "react";

export const Verifier = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <polyline points="18.35 24 24 24 29.65 24" />
      <polyline points="24 29.65 24 24 24 18.35" />
      <path d="M24,11.67c-6.81,0-12.33,5.52-12.33,12.33s5.52,12.33,12.33,12.33,12.33-5.52,12.33-12.33-5.52-12.33-12.33-12.33Z" />
      <path d="M24,4.48c-10.79.21-19.52,9.2-19.52,19.52s8.74,19.52,19.52,19.52,19.52-8.74,19.52-19.52S34.78,4.27,24,4.48ZM24,36.33c-6.81,0-12.33-5.52-12.33-12.33s5.52-12.33,12.33-12.33,12.33,5.52,12.33,12.33-5.52,12.33-12.33,12.33Z" />
      <circle
        cx="24"
        cy="24"
        r="12.33"
        transform="translate(-7.36 11.01) rotate(-22.5)"
      />
      <circle cx="24" cy="24" r="12.33" />
      <polyline points="55 24 43.52 24 36.33 24" />
      <polyline points="11.67 24 4.48 24 -7 24" />
      <polyline points="24 -7 24 4.48 24 11.67" />
      <polyline points="24 36.33 24 43.52 24 55" />
    </svg>
  );
};
