// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, { createContext, useState, useContext } from "react";

const ThemeContext = createContext({
  isSidebarOpen: false,
});

export const ThemeContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <ThemeContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  return context;
};
