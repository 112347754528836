// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";

import styles from "./CardNav.module.css";

const navItem = [
  { title: "Keywords", icon: "keyword" },
  { title: "Modify", icon: "edit" },
  { title: "Download", icon: "download" },
  { title: "Delete", icon: "delete" },
];

export const CardNav = ({ item }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {navItem.map((item, index) => (
          <li key={index}>
            <Icons iconType={item.icon} sizing />
            {item.title}
          </li>
        ))}
      </ul>
    </div>
  );
};
