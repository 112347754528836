// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router";

import { useProviders } from "../../../context/providersContext";
import { useScene } from "../../../context/sceneContext";

import { Item } from "../Item/Item";
import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { withToolTip } from "../../../../web-utils/src/UI/Component/React/V1/Hooks/withToolTip/withToolTip";

import styles from "./HomeCard.module.css";

const RefreshButton = withToolTip(Button);

export const HomeCard = ({
  scenes,
  providers,
  title,
  selectedScene,
  handleSelect,
}) => {
  const { refreshProvider } = useProviders();
  const { getScenes } = useScene();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(0);

  const providerItem = useMemo(() => {
    return providers
      .map((provider) => {
        if (provider.scene_dimension === title && provider.provider_id) {
          return provider;
        }
        return null;
      })
      .filter(Boolean);
  }, [providers, title]);

  const providerList = useMemo(
    () =>
      providerItem?.flatMap((provider) =>
        scenes?.filter((el) => el.provider_id === provider.provider_id),
      ),
    [scenes, providerItem],
  );

  // Calculate items per page based on window width
  const updateItemsPerPage = () => {
    if (window.innerWidth >= 1200) {
      setItemsPerPage(4);
    } else if (window.innerWidth >= 768) {
      setItemsPerPage(3);
    } else if (window.innerWidth >= 576) {
      setItemsPerPage(2);
    } else {
      setItemsPerPage(1);
    }
  };

  useEffect(() => {
    // Set initial items per page based on window width
    updateItemsPerPage();

    // Update items per page on window resize
    window.addEventListener("resize", updateItemsPerPage);
    return () => window.removeEventListener("resize", updateItemsPerPage);
  }, []);

  const nextSlide = () => {
    if (currentIndex < providerList.length - itemsPerPage) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleRefresh = () => {
    const refreshRequests = providerItem.map((provider) =>
      refreshProvider(provider.mosys_id),
    );
    Promise.all(refreshRequests).then(() => getScenes());
  };

  return (
    <>
      {!providerList?.length ? null : (
        <div className={styles.container}>
          <div className={styles.header}>
            {/* TODO make a component for title */}
            <div className={styles.title}>
              <Link to={`provider/${title}`}>{title && <h2>{title}</h2>}</Link>
              <RefreshButton
                onClick={() => handleRefresh()}
                iconType="undo"
                iconColor="var(--color-primary)"
                color="light"
                tooltipText="refresh connection to Frame.io"
                position="bottom"
              />
            </div>
            <div className={styles.slideNavigation}>
              <Button
                iconType="back"
                iconSize="14px"
                iconColor={`var(--color-primary)`}
                onClick={() => prevSlide()}
                color="primary"
                round
              />
              <Button
                iconType="forward"
                iconSize="14px"
                iconColor={`var(--color-primary)`}
                onClick={() => nextSlide()}
                color="primary"
                round
              />
            </div>
          </div>

          <div
            className={styles.carrouselSlides}
            style={{
              transform: `translateX(-${(100 / itemsPerPage) * currentIndex}%)`,
              width: `${(100 / itemsPerPage) * providerList.length}%`,
              maxWidth: `${providerList.length * 200}`,
            }}
          >
            {providerList.map((el, index) => (
              <div key={index} className={styles.slide}>
                <Item
                  item={el}
                  handleSelect={handleSelect}
                  selectedScene={selectedScene}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
