import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Icons } from "../Icons/Icons";

import styles from "./InputFile.module.css";

export const InputFile = ({ name, value, ...props }) => {
  const { handleFileChange, fileData } = props;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <>
          {fileData ? null : (
            <>
              <label htmlFor={field.name} className={styles.file_label}>
                <Icons
                  iconType="upload"
                  iconColor={`var(--color-primary)`}
                  sizing
                />
                Select file
              </label>
              <input
                type="file"
                name={field.name}
                id={field.name}
                value={field.value}
                className={styles.input}
                onChange={(e) => handleFileChange(e)}
              />
            </>
          )}
        </>
      )}
    />
  );
};
