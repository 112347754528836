import React from "react";

import { Icons } from "../Icons/Icons";

import styles from "./Alert.module.css";

export const Alert = ({ variant, message, iconSize = "20px" }) => {
  return (
    <div className={styles.alert_container}>
      <div>
        <Icons
          iconType={variant}
          iconColor={`var(--color-${variant})`}
          iconSize={iconSize}
        />
      </div>
      <div>{message}</div>
    </div>
  );
};
