// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
export const initialState = {
  studios: [],
  studio: {
    studio_scenes: [],
    connected: false,
  },
  studiosIsLoading: false,
  error: null,
};

export const studiosReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_START":
      return {
        ...state,
        studiosIsLoading: true,
        error: null,
      };

    case "LOAD_ERROR":
      return {
        ...state,
        studiosIsLoading: false,
        error: action.payload,
      };
    case "CLEAR_ERROR":
      return {
        ...state,
        error: null,
      };
    case "LOAD_STUDIOS":
      return {
        ...state,
        studios: action.payload,
        studiosIsLoading: false,
        error: null,
      };
    case "ADD_STUDIO_TO_STUDIOS":
      return {
        ...state,
        studios: [...state.studios, action.payload],
        studiosIsLoading: false,
        error: null,
      };
    case "LOAD_STUDIO_PROFILE":
      return {
        ...state,
        studio: action.payload,
        studiosIsLoading: false,
        error: null,
      };
    case "ADD_SCENE_TO_STUDIO":
      return {
        ...state,
        studio: {
          ...state.studio,
          studio_scenes: [
            ...(state.studio?.studio_scenes || []),
            action.payload,
          ],
        },
        studiosIsLoading: false,
        error: null,
      };
    case "ADD_MEMBER_TO_STUDIO":
      return {
        ...state,
        studiosIsLoading: false,
        error: null,
      };
    case "WS_CONNECTED":
      return {
        ...state,
        studio: {
          ...state.studio,
          connected: true,
        },
        error: null,
      };
    case "ERROR_WS":
      return {
        ...state,
        studio: {
          ...state.studio,
          connected: false,
        },
        error: action.payload,
      };
    case "SceneInStudioCurrentStateChanged": {
      return {
        ...state,
        studio: {
          ...state.studio,
          studio_scenes: state.studio.studio_scenes.map((scene) =>
            scene.mosys_id === action.payload?.sceneUUID
              ? {
                  ...scene,
                  status: action.payload?.status,
                  download_percent:
                    action.payload?.status === "downloading" ||
                    action.payload?.status === "loading"
                      ? action.payload?.downloadPercentage
                      : scene.download_percent,
                }
              : scene,
          ),
        },
      };
    }
    default:
      return state;
  }
};
