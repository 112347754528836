import React from "react";

export const Menu = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
      strokeLinecap="round"
    >
      <line x1="2" y1="24" x2="46" y2="24" />
      <line x1="2" y1="14" x2="46" y2="14" />
      <line x1="2" y1="34" x2="46" y2="34" />
    </svg>
  );
};
