import React from 'react';

import styles from './Card.module.css';

export const Card = (props) => {
  const {
    imageUrl,
    title,
    description,
    contentFooter,
    status,
    onClick,
    classname,
  } = props;
  return (
    <div className={`${styles.card} ${classname || ''}`}>
      <div className={styles.cardMedia} onClick={onClick}>
        <img
          className={styles.cardImage}
          src={imageUrl}
          alt={title}
        />
        <div className={styles.card_status}>{status}</div>
      </div>
      <div className={styles.cardContent} onClick={onClick}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
      <div className={styles.cardFooter}>{contentFooter}</div>
    </div>
  );
};
