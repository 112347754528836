import React from "react";

import styles from "./Scroll.module.css";

export const Scroll = ({ height = "80vh", children }) => {
  return (
    <div className={styles.container} style={{ height }}>
      {children}
    </div>
  );
};
