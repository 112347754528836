import React from "react";

import { NavBar } from "../../NavBar/NavBar";
import { Footer } from "../../Footer/Footer";

import styles from "./RootLayout.module.css";

export const RootLayout = ({ navigation, footer, version, children }) => {
  return (
    <div className={styles.root_container}>
      <NavBar navigation={navigation} />
      <div className={styles.dashboard}>{children}</div>
      <Footer footer={footer} version={version} />
    </div>
  );
};
