import React from "react";

export const List = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <rect x="2.46" y="2.58" width="9.86" height="10" rx="2.98" ry="2.98" />
      <line x1="22.38" y1="7.58" x2="45.38" y2="7.58" strokeLinecap="round" />
      <rect x="2.46" y="18.96" width="9.86" height="10" rx="2.98" ry="2.98" />
      <line x1="22.38" y1="23.96" x2="45.38" y2="23.96" strokeLinecap="round" />
      <rect x="2.46" y="35.42" width="9.86" height="10" rx="2.98" ry="2.98" />
      <line x1="22.38" y1="40.42" x2="45.38" y2="40.42" strokeLinecap="round" />
    </svg>
  );
};
