// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { studiosReducer, initialState } from "../reducers/studiosReducer";
import { useAxios } from "../../web-utils/src/services/axiosInstance";

const REACT_APP_API_URL = window.env && window.env.REACT_APP_API_URL;

const StudiosContext = createContext();

export const StudiosProvider = ({ children }) => {
  const [studios, dispatch] = useReducer(studiosReducer, initialState);
  const axiosInstance = useAxios();

  const getStudios = useCallback(async () => {
    dispatch({ type: "LOAD_START" });
    try {
      const response = await axiosInstance.get(
        `${REACT_APP_API_URL}studio/all/`,
      );
      dispatch({ type: "LOAD_STUDIOS", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const getStudioById = useCallback(
    async (studioId) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.get(
          `${REACT_APP_API_URL}studio/${studioId}`,
        );
        dispatch({
          type: "LOAD_STUDIO_PROFILE",
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const getStudioByName = useCallback(
    async (studioName) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.get(
          `${REACT_APP_API_URL}studio/name/${studioName}`,
        );
        dispatch({
          type: "LOAD_STUDIO_PROFILE",
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const createStudio = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}studio/`,
          data,
        );
        dispatch({
          type: "ADD_STUDIO_TO_STUDIOS",
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const addSceneToStudio = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}studio/add/scene/`,
          data,
        );
        dispatch({
          type: "ADD_SCENE_TO_STUDIO",
          payload: response.data,
        });
        return response.data;
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail[0]?.msg
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const addMemberToStudio = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}studio/add/member/`,
          data,
        );
        dispatch({
          type: "ADD_MEMBER_TO_STUDIO",
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  return (
    <StudiosContext.Provider
      value={{
        studios,
        dispatch,
        getStudios,
        getStudioById,
        getStudioByName,
        createStudio,
        addMemberToStudio,
        addSceneToStudio,
      }}
    >
      {children}
    </StudiosContext.Provider>
  );
};

export const useStudios = () => {
  const context = useContext(StudiosContext);
  return context;
};
