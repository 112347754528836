// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState } from "react";

import { Badge } from "../../../../web-utils/src/UI/Component/React/V1/Badge/Badge";
import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";

import styles from "./Keywords.module.css";

export const Keywords = ({ keywords, handleClick, deleteKeyword = false }) => {
  const [hoveredId, setHoveredId] = useState(null);

  return (
    <div className={styles.container}>
      {!keywords?.length ? null : (
        <>
          {keywords.map((el) => (
            <Badge
              key={el.keyword_id}
              status={
                <div
                  className={styles.wrapper}
                  onMouseEnter={() => setHoveredId(el.keyword_id)}
                  onMouseLeave={() => setHoveredId(null)}
                  onClick={() => handleClick(el.keyword_id)}
                >
                  # {el.word}
                  {deleteKeyword && (
                    <Button
                      color="light"
                      iconType="close"
                      iconColor="var(--color-tertiary)"
                      iconSize="14px"
                    />
                  )}
                </div>
              }
              variant={hoveredId === el.keyword_id ? "primary" : "tertiary"}
              outline
            />
          ))}
        </>
      )}
    </div>
  );
};
