import React from 'react';

import { Minus } from './Misc/Minus';
import { Plus } from './Misc/Plus';
import { Led } from './Misc/Led';
import { Share } from './Misc/Share';
import { Logout } from './Misc/Logout';
import { Data } from './Misc/Data';
import { Lens } from './Misc/Lens';
import { Network } from './Misc/Network';
import { Mapping } from './Misc/Mapping';
import { Add } from './Misc/Add';
import { ArrowDown } from './Misc/ArrowDown';
import { ArrowUp } from './Misc/ArrowUp';
import { ArrowLeft } from './Misc/ArrowLeft';
import { ArrowRight } from './Misc/ArrowRight';
import { Menu } from './Misc/Menu';
import { AddStars } from './Misc/AddStars';
import { LinkExternal } from './Misc/LinkExternal';
import { Close } from './Misc/Close';
import { StarStatus } from './Misc/StarStatus';
import { Error } from './Misc/Error';
import { Check } from './Misc/Check';
import { Success } from './Misc/Success';
import { Delay } from './Misc/Delay';
import { List } from './Misc/List';
import { Settings } from './Misc/Settings';
import { DownLoad } from './Misc/DownLoad';
import { Warning } from './Misc/Warning';
import { Info } from './Misc/Info';
import { Upload } from './Misc/Upload';
import { Support } from './Misc/Support';
import { Feedback } from './Misc/Feedback';
import { Search } from './Misc/Search';
import { Searchnotfound } from './Misc/Searchnotfound';
import { Offline } from './Misc/Offline';
import { DataOff } from './Misc/DataOff';
import { ManageUsers } from './Misc/ManageUsers';
import { User } from './Misc/User';
import { Devicesmanage } from './Misc/Devicesmanage';
import { LedRing } from './Misc/LedRing';
import { Link } from './Misc/Link';
import { LinkOff } from './Misc/LinkOff';
import { Undo } from './Misc/Undo';
import { Help } from './Misc/Help';
import { Edit } from './Misc/Edit';
import { Delete } from './Misc/Delete';
import { Alert } from './Misc/Alert';
import { Offsets } from './Misc/Offsets';
import { Explore } from './Misc/Explore';
import { Librairy } from './Misc/Librairy';
import { Threedots } from './Misc/Threedots';
import { Keyword } from './Misc/Keyword';
import { Control } from './Misc/Control';
import { ControlBack } from './Misc/ControlBack';

import { Geometry } from './Misc/Geometry';
import { LensCalibration } from './Misc/LensCalibration';
import { Verifier } from './Misc/Verifier';
import { Ptz } from './Misc/Ptz';
import { Connection } from './Misc/Connection';
import { Record } from './Misc/Record';
import { DataPacket } from './Misc/DataPacket';
import { Vision } from './Misc/Vision';

import styles from './Icons.module.css';

export const Icons = ({ ...props }) => {
  const {
    iconType,
    iconColor = '#ffffff',
    iconSize = '24',
    sizing,
    background,
  } = props;
  return (
    <>
      {!iconType ? null : (
        <span
          className={`${styles.icons} ${sizing && styles.sizing} ${background && styles.background}`}
          data-testid="svg-icon"
        >
          {(() => {
            switch (iconType) {
              case 'plusForm':
                return <Plus color={iconColor} size={iconSize} />;
              case 'minusForm':
                return <Minus color={iconColor} size={iconSize} />;
              case 'ledOutput':
                return <Led color={iconColor} size={iconSize} />;
              case 'share':
                return <Share color={iconColor} size={iconSize} />;
              case 'logout':
                return <Logout color={iconColor} size={iconSize} />;
              case 'alert':
                return <Alert color={iconColor} size={iconSize} />;
              case 'data':
                return <Data color={iconColor} size={iconSize} />;
              case 'history':
                return <List color={iconColor} size={iconSize} />;
              case 'delay':
                return <Delay color={iconColor} size={iconSize} />;
              case 'offsets':
                return <Offsets color={iconColor} size={iconSize} />;
              case 'verifier':
                return <Verifier color={iconColor} size={iconSize} />;
              case 'lens':
                return <Lens color={iconColor} size={iconSize} />;
              case 'mapping':
                return <Mapping color={iconColor} size={iconSize} />;
              case 'network':
                return <Network color={iconColor} size={iconSize} />;
              case 'connection':
                return (
                  <Connection color={iconColor} size={iconSize} />
                );
              case 'offline':
                return <Offline color={iconColor} size={iconSize} />;
              case 'dataOff':
                return <DataOff color={iconColor} size={iconSize} />;
              case 'geometry':
                return <Geometry color={iconColor} size={iconSize} />;
              case 'vision':
                return <Vision color={iconColor} size={iconSize} />;
              case 'user':
                return <User color={iconColor} size={iconSize} />;
              case 'users':
                return (
                  <ManageUsers color={iconColor} size={iconSize} />
                );
              case 'devices':
                return (
                  <Devicesmanage color={iconColor} size={iconSize} />
                );
              case 'ptz':
                return <Ptz color={iconColor} size={iconSize} />;
              case 'settings':
                return <Settings color={iconColor} size={iconSize} />;
              case 'back':
                return (
                  <ArrowLeft color={iconColor} size={iconSize} />
                );
              case 'forward':
                return (
                  <ArrowRight color={iconColor} size={iconSize} />
                );
              case 'down':
                return (
                  <ArrowDown color={iconColor} size={iconSize} />
                );
              case 'up':
                return <ArrowUp color={iconColor} size={iconSize} />;
              case 'add':
                return <Add color={iconColor} size={iconSize} />;
              case 'menu':
                return <Menu color={iconColor} size={iconSize} />;
              case 'stars':
                return <AddStars color={iconColor} size={iconSize} />;
              case 'close':
                return <Close color={iconColor} size={iconSize} />;
              case 'status':
                return (
                  <StarStatus color={iconColor} size={iconSize} />
                );
              case 'error':
                return <Error color={iconColor} size={iconSize} />;
              case 'success':
                return <Check color={iconColor} size={iconSize} />;
              case 'warning':
                return <Warning color={iconColor} size={iconSize} />;
              case 'info':
                return <Info color={iconColor} size={iconSize} />;
              case 'isSuccess':
                return <Success color={iconColor} size={iconSize} />;
              case 'help':
                return <Help color={iconColor} size={iconSize} />;
              case 'upload':
                return <Upload color={iconColor} size={iconSize} />;
              case 'download':
                return <DownLoad color={iconColor} size={iconSize} />;
              case 'led':
                return <LedRing color={iconColor} size={iconSize} />;
              case 'edit':
                return <Edit color={iconColor} size={iconSize} />;
              case 'search':
                return <Search color={iconColor} size={iconSize} />;
              case 'searchnotfound':
                return (
                  <Searchnotfound color={iconColor} size={iconSize} />
                );
              case 'support':
                return <Support color={iconColor} size={iconSize} />;
              case 'feedback':
                return <Feedback color={iconColor} size={iconSize} />;
              case 'delete':
                return <Delete color={iconColor} size={iconSize} />;
              case 'link':
                return <Link color={iconColor} size={iconSize} />;
              case 'linkExternal':
                return (
                  <LinkExternal color={iconColor} size={iconSize} />
                );
              case 'linkoff':
                return <LinkOff color={iconColor} size={iconSize} />;
              case 'undo':
                return <Undo color={iconColor} size={iconSize} />;
              case 'explore':
                return <Explore color={iconColor} size={iconSize} />;
              case 'librairy':
                return <Librairy color={iconColor} size={iconSize} />;
              case 'threeDots':
                return (
                  <Threedots color={iconColor} size={iconSize} />
                );
              case 'keyword':
                return <Keyword color={iconColor} size={iconSize} />;
              case 'control':
                return <Control color={iconColor} size={iconSize} />;
              case 'controlback':
                return (
                  <ControlBack color={iconColor} size={iconSize} />
                );
              case 'lensCalibration':
                return (
                  <LensCalibration
                    color={iconColor}
                    size={iconSize}
                  />
                );
              case 'record':
                return <Record color={iconColor} size={iconSize} />;
              case 'dataPacket':
                return (
                  <DataPacket color={iconColor} size={iconSize} />
                );
              default:
                return null;
            }
          })()}
        </span>
      )}
    </>
  );
};
