// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useMemo } from "react";

import { useProviders } from "../../../context/providersContext";

import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { StudioInfo } from "../StudioInfo/StudioInfo";
import { SizeInBytes } from "../SizeInBytes/SizeInBytes";
import { Keywords } from "../Keywords/Keywords";

import { Scroll } from "../../../../web-utils/src/UI/Component/React/V1/Scroll/Scroll";

import styles from "./Details.module.css";

export const Details = ({ item }) => {
  const [isOpen, setIsOpen] = useState(true);
  const { providers } = useProviders();

  const image = useMemo(() => {
    return item?.thumbnail
      ? `data:;base64,${item?.thumbnail}`
      : "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
  }, [item?.thumbnail]);

  const providerItem = useMemo(
    () =>
      providers?.providers.find((el) => el.provider_id === item.provider_id),
    [providers, item],
  );

  return (
    <div className={styles.container}>
      <Scroll height="100%">
        <div className={styles.content}>
          <div
            className={`${isOpen ? styles.headerOpen : styles.headerClose}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <Button
              color="light"
              onClick={() => setIsOpen(!isOpen)}
              iconType={!isOpen ? "control" : "controlback"}
              iconSize="24px"
            />
            <p>Details</p>
          </div>
          <div className={`${isOpen ? styles.body : styles.bodyClose}`}>
            <div className={styles.bodyContent}>
              <h3>{item.name}</h3>
              <div className={styles.imageContainer}>
                <img src={image} alt={item.name} />
              </div>
              <p>{item.description}</p>
              <p>{item?.author}</p>
              <div className={styles.wrapper}>
                {!providerItem ? null : (
                  <div className={styles.wrapper}>
                    <p>{providerItem?.scene_dimension} /</p>
                    <p>{providerItem?.name}</p>
                  </div>
                )}
                <div>
                  <SizeInBytes size={item?.size_bytes} />
                </div>
              </div>
              {!item.keywords?.length ? null : (
                <Keywords keywords={item.keywords} deleteKeyword={true} />
              )}
            </div>
            <StudioInfo scene={item} provider={providerItem} />
          </div>
        </div>
      </Scroll>
    </div>
  );
};
