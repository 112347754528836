// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { withToolTip } from "../../../../web-utils/src/UI/Component/React/V1/Hooks/withToolTip/withToolTip";

import styles from "./CardFooter.module.css";

const ListKeywords = ({ keywords, flexDirection = "row" }) => {
  const list = keywords.map((el) => (
    <li key={el.keyword.keyword_id} className={styles.keyword}>
      #{el.keyword?.word}
    </li>
  ));
  return (
    <ul className={styles.keywordList} style={{ flexDirection }}>
      {list}
    </ul>
  );
};

const List = withToolTip(ListKeywords);

export const CardFooter = ({ itemKeywords, setIsNavOpen, isNavOpen }) => {
  return (
    <div className={styles.actions}>
      <div className={styles.keywordWrapper}>
        {!itemKeywords?.length ? null : (
          <>
            {itemKeywords?.length >= 3 ? (
              <List
                keywords={itemKeywords}
                tooltipText={
                  <ListKeywords
                    keywords={itemKeywords}
                    flexDirection="column"
                  />
                }
              />
            ) : (
              <ListKeywords keywords={itemKeywords} />
            )}
          </>
        )}
      </div>
      <div className={styles.dropdown}>
        <Button
          onClick={() => setIsNavOpen(!isNavOpen)}
          iconType="threeDots"
          color="light"
          iconSize="16px"
        />
      </div>
    </div>
  );
};
