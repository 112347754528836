import React from "react";

export const ManageUsers = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <circle cx="34.13" cy="17.2" r="6.74" />
      <path d="M46.14,30.16c0,5.25-5.15,11.45-11.5,11.45-2.22,0-4.29-.76-6.04-1.97,2.21-2.65,3.54-5.91,3.54-8.91,0-1.35-1.98-2.35-4.9-3,6.61-1.38,18.9-.57,18.9,2.43Z" />
      <circle cx="17" cy="14.39" r="8.5" />
      <path d="M32.14,30.73c0,3-1.33,6.26-3.54,8.91-2.66,3.2-6.58,5.54-10.96,5.54-8.01,0-14.49-7.82-14.5-14.45,0-3.82,15.84-4.82,24.1-3,2.92.65,4.9,1.65,4.9,3Z" />
    </svg>
  );
};
