import React from "react";
import { NavLink } from "react-router";

import { Icons } from "../Icons/Icons";

import styles from "./link.module.css";

export const LinkComponent = (props) => {
  const { href, sidebar, externalLink, onClick, children, ...rest } = props;
  return (
    <NavLink
      to={href}
      onClick={onClick}
      className={({ isActive }) =>
        `${isActive ? (sidebar ? styles.sidebar_active : styles.active) : ""} ${styles.link}`
      }
      target={externalLink ? "_blank" : "_self"}
      data-testid="link"
      end
    >
      <span className={`${sidebar ? styles.navbar_link : styles.link_item}`}>
        <Icons sizing {...rest} />
        {children}
      </span>
      {externalLink && <Icons iconType="linkExternal" iconSize={20} />}
    </NavLink>
  );
};
