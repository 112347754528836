import React from "react";

export const Vision = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
      <path d="M43.4,30.76l-39.3-.03c-2.1-.36-2.1-2.08-2.1-5.76v-1.29c-.02-4.05,0-6.34,2.1-6.42l39.35-.03c2.14-.03,2.53.98,2.53,5.42l.02,1.89c.02,5.24.02,5.92-2.6,6.22Z" />
      <path d="M18.28,24.04c0,2.64-2.09,4.77-4.64,4.75s-4.58-2.15-4.58-4.75,2.04-4.73,4.58-4.75c2.56-.02,4.64,2.11,4.64,4.75Z" />
      <path d="M39.06,23.93c0,2.64-2.09,4.77-4.64,4.75-2.54-.02-4.58-2.15-4.58-4.75s2.04-4.73,4.58-4.75c2.56-.02,4.64,2.11,4.64,4.75Z" />
    </svg>
  );
};
