// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

// TODO Merge with studiosContext
import React, {
  createContext,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";

import { useAuth } from "../../../web-utils/src/context/authContext";
import { useStudios } from "../studiosContext";

const REACT_APP_WEBSOCKET_URL = window.env?.REACT_APP_WEBSOCKET_URL || "";

export const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const { token } = useAuth();
  const { studios, dispatch } = useStudios();
  const { studio } = studios;

  const ws = useRef(null);
  const reconnectAttempt = useRef(0);

  const maxReconnectAttempts = 5; // Maximum reconnection attempts

  const send = (payload) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(payload));
    }
  };

  const connectWebSocket = useCallback(() => {
    if (token && studio?.mosys_id) {
      // Encode the token in Base64
      const encodedToken = btoa(token);
      // Create a new WebSocket connection
      ws.current = new WebSocket(REACT_APP_WEBSOCKET_URL, encodedToken);

      // Handle WebSocket open event
      ws.current.onopen = () => {
        reconnectAttempt.current = 0;
        dispatch({ type: "WS_CONNECTED" });
      };
      // Handle WebSocket message event
      ws.current.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          if (response?.body) {
            const { event: eventName, data } = response.body;
            dispatch({
              type: eventName,
              payload: {
                sceneUUID: data?.sceneUUID,
                status: data?.state,
                downloadPercentage: data?.progressPercentage,
              },
            });
          }
        } catch (error) {
          dispatch({
            type: "LOAD_ERROR",
            payload: "Unable to process incoming WebSocket message",
          });
        }
      };

      ws.current.onerror = () => {
        dispatch({
          type: "ERROR_WS",
          payload: "Connection to studio failed",
        });
      };

      // Handle WebSocket close event
      ws.current.onclose = () => {
        // Attempt to reconnect
        if (reconnectAttempt.current < maxReconnectAttempts) {
          reconnectAttempt.current += 1;
          setTimeout(connectWebSocket, 2000);
        } else {
          // Give up reconnection after 5 attempts to give user feedbacks
          // potentially remove and always reconnect. see: https://gitlab.mo-sys.com/CCS/project-c-ui/-/merge_requests/1#note_34242
          dispatch({
            type: "ERROR_WS",
            payload: "Connection to studio failed",
          });
        }
      };
      return () => {
        if (ws.current) {
          ws.current.close();
        }
      };
    }
  }, [token, studio?.mosys_id, dispatch]);

  useEffect(() => {
    connectWebSocket();
  }, [connectWebSocket]);

  // register studio
  useEffect(() => {
    if (studio?.connected && studio?.mosys_id) {
      send({
        type: "register",
        body: {
          event: "AllScenesInStudioCurrentStateChanged",
          data: {
            studioUUID: studio?.mosys_id,
          },
        },
      });
    }
  }, [studio?.connected, studio?.mosys_id]);

  // Close WebSocket connection when studio change
  const closeWebSocket = useCallback(() => {
    if (ws.current) {
      send({
        type: "deregister",
        body: {
          event: "AllScenesInStudioCurrentStateChanged",
          data: {
            studioUUID: studio?.mosys_id,
          },
        },
      });
      ws.current.close();
    }
  }, [studio?.mosys_id]);

  return (
    <WebSocketContext.Provider
      value={{ send, closeWebSocket, connectWebSocket }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebsocket = () => {
  const context = useContext(WebSocketContext);
  return context;
};
