// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { lazy, Suspense } from "react";
import {
  RouterProvider,
  // createBrowserRouter,
  createHashRouter,
} from "react-router";

import { Root, Error } from "./pages/Root/Root";
import { ProtectedRoute } from "../web-utils/src/routes/ProtectedRoute";
import LoginPage from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashbord";
import Layout from "./pages/Layout/Layout";
import Scenes from "./pages/ScenesAll/Scenes";
import ProtectedAdminRoute from "./pages/ProtectedAdminRoute";

import { Loader } from "../web-utils/src/UI/Component/React/V1/Loader/Loader";

const ScenesByType = lazy(() => import("./pages/SceneByType/SceneByType"));
const SceneByStatus = lazy(() => import("./pages/SceneByStatus/SceneByStatus"));
const Users = lazy(() => import("./pages/Users/Users"));
const Companies = lazy(() => import("./pages/Companies/Companies"));

function App() {
  // Define router using createHashRouter (v7 compatible)
  const router = createHashRouter([
    {
      path: "/",
      element: <Root />,
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: (
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "/",
              element: <Layout />,
              children: [
                {
                  index: true, // equivalent to `<Route index element={Scenes} />`
                  element: <Scenes />,
                },
                {
                  path: "provider/:sceneType",
                  element: (
                    <Suspense>
                      <ScenesByType />
                    </Suspense>
                  ),
                },
                {
                  path: "scene/:sceneStatus",
                  element: (
                    <Suspense>
                      <SceneByStatus />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "/admin",
          element: <ProtectedAdminRoute />,
          children: [
            {
              path: "users",
              element: (
                <Suspense>
                  <Users />
                </Suspense>
              ),
            },
            {
              path: "companies",
              element: (
                <Suspense>
                  <Companies />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: "modify",
          element: (
            <div>
              <h2>Coming soon</h2>
            </div>
          ),
        },
        {
          path: "studios",
          element: (
            <div>
              <h2>Coming soon</h2>
            </div>
          ),
        },
        {
          path: "login",
          element: <LoginPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} fallbackElement={<Loader />} />;
}

export default App;
