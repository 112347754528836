// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useEffect } from "react";

import { Outlet } from "react-router";

import { useStudios } from "../../context/studiosContext";
import { useUsers } from "../../context/usersContext";
import { useAuth } from "../../../web-utils/src/context/authContext";

import { useMediaQuery } from "../../hooks/useMediaQuery";
import { Sidebar } from "../../components/Navigation/Sidebar/Sidebar";

import styles from "./Dashboard.module.css";

function Dashboard() {
  const isMobile = useMediaQuery("(max-width: 576px)");
  const { studios, getStudios, getStudioById } = useStudios();
  const { getUserProfile } = useUsers();
  const { accessToken } = useAuth();

  useEffect(() => {
    if (accessToken) {
      getStudios();
      getUserProfile();
    }
  }, [getStudios, getUserProfile, accessToken]);

  useEffect(() => {
    if (studios?.studios.length === 1) {
      const studioId = studios?.studios[0]?.studio_id;
      getStudioById(studioId);
    }
  }, [studios?.studios, getStudioById]);

  return (
    <div className={styles.dashboard}>
      {isMobile ? null : <Sidebar />}
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
}

export default Dashboard;
