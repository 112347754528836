import React from "react";

export const LensCalibration = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <polyline points="14 46 2 46 2 34" />
      <polyline points="46 34 46 46 34 46" />
      <polyline points="2 14 2 2 14 2" />
      <polyline points="34 2 46 2 46 14" />
      <path d="M10.49,10.64c-.52.52-1,1.08-1.46,1.65M6.31,17.06c-.84,2.15-1.31,4.49-1.31,6.94,0,10.49,8.51,19,19,19s19-8.51,19-19S34.49,5,24,5c-2.47,0-4.83.47-7,1.33M24,36c-6.63,0-12-5.37-12-12s5.37-12,12-12,12,5.37,12,12-5.37,12-12,12Z" />
      <polyline points="54.17 24 43 24 36 24" />
      <polyline points="12 24 5 24 -6.17 24" />
      <polyline points="24 -6.17 24 5 24 12" />
      <polyline points="24 36 24 43 24 54.17" />
      <polyline points="18.5 24 24 24 29.5 24" />
      <polyline points="24 29.5 24 24 24 18.5" />
    </svg>
  );
};
