import React from "react";

export const Upload = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path
        d="M45.65,26.21v13.08c0,.93-1.34,1.68-3,1.68H5.35c-1.66,0-3-.75-3-1.68v-13.08"
        strokeLinecap="round"
      />
      <line x1="24" y1="29.1" x2="24" y2="8.78" strokeLinecap="round" />
      <polygon points="28.09 12.09 27.36 12.78 24 9.16 20.64 12.78 19.91 12.09 24 7.7 28.09 12.09" />
    </svg>
  );
};
