// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
export const initialState = {
  usersList: [],
  userProfile: null,
  loading: false,
  error: null,
};

export const usersReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_USERS":
      return {
        ...state,
        usersList: action.payload,
        loading: false,
        error: null,
      };
    case "LOAD_USER_PROFILE":
      return {
        ...state,
        userProfile: action.payload,
        loading: false,
        error: null,
      };
    case "LOAD_USER_START":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "LOAD_USER_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
