import React from "react";

export const Led = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
    >
      <g>
        <line
          x1="19.06"
          y1="13.5"
          x2="8.88"
          y2="13.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
        />
        <line
          x1="16.1"
          y1="18.5"
          x2="4.88"
          y2="18.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
        />
        <line
          x1="15.12"
          y1="24"
          x2=".88"
          y2="24"
          fill="none"
          stroke={color}
          strokeLinecap="round"
        />
        <line
          x1="15.92"
          y1="29"
          x2="4.88"
          y2="29"
          fill="none"
          stroke={color}
          strokeLinecap="round"
        />
        <line
          x1="19.06"
          y1="34.5"
          x2="8.88"
          y2="34.5"
          fill="none"
          stroke={color}
          strokeLinecap="round"
        />
        <path
          d="M15.92,29c-.52-1.57-.8-3.25-.8-5,0-1.93.34-3.79.97-5.5"
          fill="none"
          stroke={color}
        />
        <path
          d="M19.06,13.5c2.92-3.37,7.25-5.5,12.07-5.5,8.84,0,16,7.16,16,16s-7.16,16-16,16c-4.82,0-9.15-2.13-12.07-5.5"
          fill="none"
          stroke={color}
        />
      </g>
      <g>
        <g>
          <circle cx="17.19" cy="18.48" r=".41" fill={color} />
          <circle cx="18.07" cy="18.48" r=".41" fill={color} />
          <circle cx="18.95" cy="18.48" r=".41" fill={color} />
          <circle cx="17.19" cy="19.34" r=".41" fill={color} />
          <circle cx="18.07" cy="19.34" r=".41" fill={color} />
          <circle cx="18.95" cy="19.34" r=".41" fill={color} />
          <circle cx="17.19" cy="20.21" r=".41" fill={color} />
          <circle cx="18.07" cy="20.21" r=".41" fill={color} />
          <circle cx="18.95" cy="20.21" r=".41" fill={color} />
          <circle cx="17.19" cy="21.07" r=".41" fill={color} />
          <circle cx="18.07" cy="21.07" r=".41" fill={color} />
          <circle cx="18.95" cy="21.07" r=".41" fill={color} />
          <circle cx="17.19" cy="21.95" r=".41" fill={color} />
          <circle cx="18.07" cy="21.95" r=".41" fill={color} />
          <circle cx="18.95" cy="21.95" r=".41" fill={color} />
          <circle cx="17.19" cy="22.81" r=".41" fill={color} />
          <circle cx="18.07" cy="22.81" r=".41" fill={color} />
          <circle cx="18.95" cy="22.81" r=".41" fill={color} />
          <circle cx="17.19" cy="23.7" r=".41" fill={color} />
          <circle cx="18.07" cy="23.7" r=".41" fill={color} />
          <circle cx="18.95" cy="23.7" r=".41" fill={color} />
          <circle cx="17.19" cy="24.59" r=".41" fill={color} />
          <circle cx="18.07" cy="24.59" r=".41" fill={color} />
          <circle cx="18.95" cy="24.59" r=".41" fill={color} />
          <circle cx="17.19" cy="25.51" r=".41" fill={color} />
          <circle cx="18.07" cy="25.51" r=".41" fill={color} />
          <circle cx="18.95" cy="25.51" r=".41" fill={color} />
          <circle cx="17.19" cy="26.43" r=".41" fill={color} />
          <circle cx="18.07" cy="26.43" r=".41" fill={color} />
          <circle cx="18.95" cy="26.43" r=".41" fill={color} />
          <circle cx="17.19" cy="27.32" r=".41" fill={color} />
          <circle cx="18.07" cy="27.32" r=".41" fill={color} />
          <circle cx="18.95" cy="27.32" r=".41" fill={color} />
          <circle cx="17.19" cy="28.2" r=".41" fill={color} />
          <circle cx="18.07" cy="28.2" r=".41" fill={color} />
          <circle cx="18.95" cy="28.2" r=".41" fill={color} />
          <circle cx="17.19" cy="29.12" r=".41" fill={color} />
          <circle cx="18.07" cy="29.12" r=".41" fill={color} />
          <circle cx="18.95" cy="29.12" r=".41" fill={color} />
          <circle cx="19.84" cy="28.2" r=".41" fill={color} />
          <circle cx="20.72" cy="28.2" r=".41" fill={color} />
          <circle cx="21.6" cy="28.2" r=".41" fill={color} />
          <circle cx="19.84" cy="29.12" r=".41" fill={color} />
          <circle cx="20.72" cy="29.12" r=".41" fill={color} />
          <circle cx="21.6" cy="29.12" r=".41" fill={color} />
          <circle cx="22.49" cy="28.2" r=".41" fill={color} />
          <circle cx="23.37" cy="28.2" r=".41" fill={color} />
          <path
            d="M24.67,28.2c0,.16-.1.3-.23.37-.06.03-.12.04-.18.04-.23,0-.41-.19-.41-.41s.18-.41.41-.41c.06,0,.13.01.18.04.14.07.23.21.23.37Z"
            fill={color}
          />
          <circle cx="22.49" cy="29.12" r=".41" fill={color} />
          <circle cx="23.37" cy="29.12" r=".41" fill={color} />
          <circle cx="24.25" cy="29.12" r=".41" fill={color} />
          <circle cx="36.51" cy="18.38" r=".41" fill={color} />
          <circle cx="37.39" cy="18.38" r=".41" fill={color} />
          <circle cx="38.27" cy="18.38" r=".41" fill={color} />
          <circle cx="36.51" cy="19.24" r=".41" fill={color} />
          <circle cx="37.39" cy="19.24" r=".41" fill={color} />
          <circle cx="38.27" cy="19.24" r=".41" fill={color} />
          <circle cx="36.51" cy="20.11" r=".41" fill={color} />
          <circle cx="37.39" cy="20.11" r=".41" fill={color} />
          <circle cx="38.27" cy="20.11" r=".41" fill={color} />
          <circle cx="36.51" cy="20.97" r=".41" fill={color} />
          <circle cx="37.39" cy="20.97" r=".41" fill={color} />
          <circle cx="38.27" cy="20.97" r=".41" fill={color} />
          <circle cx="36.51" cy="21.85" r=".41" fill={color} />
          <circle cx="37.39" cy="21.85" r=".41" fill={color} />
          <circle cx="38.27" cy="21.85" r=".41" fill={color} />
          <circle cx="36.51" cy="22.71" r=".41" fill={color} />
          <circle cx="37.39" cy="22.71" r=".41" fill={color} />
          <circle cx="38.27" cy="22.71" r=".41" fill={color} />
          <circle cx="36.51" cy="23.6" r=".41" fill={color} />
          <circle cx="37.39" cy="23.6" r=".41" fill={color} />
          <circle cx="38.27" cy="23.6" r=".41" fill={color} />
          <circle cx="36.51" cy="24.49" r=".41" fill={color} />
          <circle cx="37.39" cy="24.49" r=".41" fill={color} />
          <circle cx="38.27" cy="24.49" r=".41" fill={color} />
          <circle cx="36.51" cy="25.41" r=".41" fill={color} />
          <circle cx="37.39" cy="25.41" r=".41" fill={color} />
          <circle cx="38.27" cy="25.41" r=".41" fill={color} />
          <circle cx="36.51" cy="26.33" r=".41" fill={color} />
          <circle cx="37.39" cy="26.33" r=".41" fill={color} />
          <circle cx="38.27" cy="26.33" r=".41" fill={color} />
          <circle cx="36.51" cy="27.22" r=".41" fill={color} />
          <circle cx="37.39" cy="27.22" r=".41" fill={color} />
          <circle cx="38.27" cy="27.22" r=".41" fill={color} />
          <circle cx="36.51" cy="28.11" r=".41" fill={color} />
          <circle cx="37.39" cy="28.11" r=".41" fill={color} />
          <circle cx="38.27" cy="28.11" r=".41" fill={color} />
          <circle cx="36.51" cy="29.02" r=".41" fill={color} />
          <circle cx="37.39" cy="29.02" r=".41" fill={color} />
          <circle cx="38.27" cy="29.02" r=".41" fill={color} />
          <circle cx="43.8" cy="19.39" r=".41" fill={color} />
          <circle cx="45.56" cy="21.12" r=".41" fill={color} />
          <circle cx="43.8" cy="20.26" r=".41" fill={color} />
          <circle cx="44.68" cy="20.26" r=".41" fill={color} />
          <circle cx="43.8" cy="21.12" r=".41" fill={color} />
          <circle cx="44.68" cy="21.12" r=".41" fill={color} />
          <circle cx="43.8" cy="22" r=".41" fill={color} />
          <circle cx="44.68" cy="22" r=".41" fill={color} />
          <circle cx="45.56" cy="22" r=".41" fill={color} />
          <circle cx="43.8" cy="22.86" r=".41" fill={color} />
          <circle cx="44.68" cy="22.86" r=".41" fill={color} />
          <circle cx="45.56" cy="22.86" r=".41" fill={color} />
          <circle cx="43.8" cy="23.75" r=".41" fill={color} />
          <circle cx="44.68" cy="23.75" r=".41" fill={color} />
          <circle cx="45.56" cy="23.75" r=".41" fill={color} />
          <circle cx="43.8" cy="24.64" r=".41" fill={color} />
          <circle cx="44.68" cy="24.64" r=".41" fill={color} />
          <circle cx="45.56" cy="24.64" r=".41" fill={color} />
          <circle cx="43.8" cy="25.56" r=".41" fill={color} />
          <circle cx="44.68" cy="25.56" r=".41" fill={color} />
          <circle cx="45.56" cy="25.56" r=".41" fill={color} />
          <circle cx="43.8" cy="26.48" r=".41" fill={color} />
          <circle cx="44.68" cy="26.48" r=".41" fill={color} />
          <circle cx="43.8" cy="27.37" r=".41" fill={color} />
          <circle cx="44.68" cy="27.37" r=".41" fill={color} />
          <circle cx="43.8" cy="28.25" r=".41" fill={color} />
          <circle cx="39.24" cy="28.08" r=".41" fill={color} />
          <circle cx="40.12" cy="28.08" r=".41" fill={color} />
          <circle cx="41.11" cy="28.09" r=".41" fill={color} />
          <circle cx="39.3" cy="29.06" r=".41" fill={color} />
          <circle cx="40.18" cy="29.06" r=".41" fill={color} />
          <circle cx="41.11" cy="29.02" r=".41" fill={color} />
          <circle cx="42.02" cy="28.09" r=".41" fill={color} />
          <circle cx="42.06" cy="28.98" r=".41" fill={color} />
          <circle cx="42.97" cy="27.88" r=".41" fill={color} />
          <circle cx="42.97" cy="28.8" r=".41" fill={color} />
          <circle cx="39.18" cy="19.35" r=".41" fill={color} />
          <circle cx="40.06" cy="19.35" r=".41" fill={color} />
          <circle cx="40.94" cy="19.35" r=".41" fill={color} />
          <circle cx="39.24" cy="18.38" r=".41" fill={color} />
          <circle cx="40.12" cy="18.38" r=".41" fill={color} />
          <circle cx="40.94" cy="18.44" r=".41" fill={color} />
          <circle cx="41.85" cy="19.4" r=".41" fill={color} />
          <circle cx="41.85" cy="18.48" r=".41" fill={color} />
          <circle cx="42.81" cy="19.58" r=".41" fill={color} />
          <circle cx="42.81" cy="18.66" r=".41" fill={color} />
        </g>
        <g>
          <circle cx="26.52" cy="18.48" r=".41" fill={color} />
          <circle cx="27.4" cy="18.48" r=".41" fill={color} />
          <circle cx="28.28" cy="18.48" r=".41" fill={color} />
          <circle cx="26.52" cy="19.34" r=".41" fill={color} />
          <circle cx="27.4" cy="19.34" r=".41" fill={color} />
          <circle cx="28.28" cy="19.34" r=".41" fill={color} />
          <circle cx="26.52" cy="20.21" r=".41" fill={color} />
          <circle cx="27.4" cy="20.21" r=".41" fill={color} />
          <circle cx="28.28" cy="20.21" r=".41" fill={color} />
          <circle cx="26.52" cy="21.07" r=".41" fill={color} />
          <circle cx="27.4" cy="21.07" r=".41" fill={color} />
          <circle cx="28.28" cy="21.07" r=".41" fill={color} />
          <circle cx="26.52" cy="21.95" r=".41" fill={color} />
          <circle cx="27.4" cy="21.95" r=".41" fill={color} />
          <circle cx="28.28" cy="21.95" r=".41" fill={color} />
          <circle cx="26.52" cy="22.81" r=".41" fill={color} />
          <circle cx="27.4" cy="22.81" r=".41" fill={color} />
          <circle cx="28.28" cy="22.81" r=".41" fill={color} />
          <circle cx="26.52" cy="23.7" r=".41" fill={color} />
          <circle cx="27.4" cy="23.7" r=".41" fill={color} />
          <circle cx="28.28" cy="23.7" r=".41" fill={color} />
          <circle cx="26.52" cy="24.59" r=".41" fill={color} />
          <circle cx="27.4" cy="24.59" r=".41" fill={color} />
          <circle cx="28.28" cy="24.59" r=".41" fill={color} />
          <circle cx="26.52" cy="25.51" r=".41" fill={color} />
          <circle cx="27.4" cy="25.51" r=".41" fill={color} />
          <circle cx="28.28" cy="25.51" r=".41" fill={color} />
          <circle cx="26.52" cy="26.43" r=".41" fill={color} />
          <circle cx="27.4" cy="26.43" r=".41" fill={color} />
          <circle cx="28.28" cy="26.43" r=".41" fill={color} />
          <circle cx="26.52" cy="27.32" r=".41" fill={color} />
          <circle cx="27.4" cy="27.32" r=".41" fill={color} />
          <circle cx="28.28" cy="27.32" r=".41" fill={color} />
          <circle cx="26.52" cy="28.21" r=".41" fill={color} />
          <circle cx="27.4" cy="28.21" r=".41" fill={color} />
          <circle cx="28.28" cy="28.21" r=".41" fill={color} />
          <circle cx="26.52" cy="29.12" r=".41" fill={color} />
          <circle cx="27.4" cy="29.12" r=".41" fill={color} />
          <circle cx="28.28" cy="29.12" r=".41" fill={color} />
          <circle cx="29.17" cy="28.21" r=".41" fill={color} />
          <circle cx="30.05" cy="28.21" r=".41" fill={color} />
          <circle cx="30.93" cy="28.21" r=".41" fill={color} />
          <circle cx="29.17" cy="29.12" r=".41" fill={color} />
          <circle cx="30.05" cy="29.12" r=".41" fill={color} />
          <circle cx="30.93" cy="29.12" r=".41" fill={color} />
          <circle cx="31.83" cy="28.21" r=".41" fill={color} />
          <circle cx="32.71" cy="28.21" r=".41" fill={color} />
          <path
            d="M34,28.21c0,.16-.1.3-.23.37-.06.03-.12.04-.18.04-.23,0-.41-.19-.41-.41s.18-.41.41-.41c.06,0,.13.01.18.04.14.07.23.21.23.37Z"
            fill={color}
          />
          <circle cx="31.83" cy="29.12" r=".41" fill={color} />
          <circle cx="32.71" cy="29.12" r=".41" fill={color} />
          <circle cx="33.59" cy="29.12" r=".41" fill={color} />
          <circle cx="29.2" cy="18.47" r=".41" fill={color} />
          <circle cx="30.08" cy="18.47" r=".41" fill={color} />
          <circle cx="30.96" cy="18.47" r=".41" fill={color} />
          <circle cx="29.2" cy="19.39" r=".41" fill={color} />
          <circle cx="30.08" cy="19.39" r=".41" fill={color} />
          <circle cx="30.96" cy="19.39" r=".41" fill={color} />
          <circle cx="31.86" cy="18.47" r=".41" fill={color} />
          <circle cx="32.74" cy="18.47" r=".41" fill={color} />
          <path
            d="M34.03,18.47c0,.16-.1.3-.23.37-.06.03-.12.04-.18.04-.23,0-.41-.19-.41-.41s.18-.41.41-.41c.06,0,.13.01.18.04.14.07.23.21.23.37Z"
            fill={color}
          />
          <circle cx="31.86" cy="19.39" r=".41" fill={color} />
          <circle cx="32.74" cy="19.39" r=".41" fill={color} />
          <circle cx="33.62" cy="19.39" r=".41" fill={color} />
          <circle cx="29.13" cy="23.2" r=".41" fill={color} />
          <circle cx="30.01" cy="23.2" r=".41" fill={color} />
          <circle cx="29.13" cy="24.11" r=".41" fill={color} />
          <circle cx="30.01" cy="24.11" r=".41" fill={color} />
          <circle cx="30.9" cy="23.2" r=".41" fill={color} />
          <circle cx="31.78" cy="23.2" r=".41" fill={color} />
          <path
            d="M33.08,23.2c0,.16-.1.3-.23.37-.06.03-.12.04-.18.04-.23,0-.41-.19-.41-.41s.18-.41.41-.41c.06,0,.13.01.18.04.14.07.23.21.23.37Z"
            fill={color}
          />
          <circle cx="30.9" cy="24.11" r=".41" fill={color} />
          <circle cx="31.78" cy="24.11" r=".41" fill={color} />
          <circle cx="32.66" cy="24.11" r=".41" fill={color} />
        </g>
      </g>
    </svg>
  );
};
