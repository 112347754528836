import React from "react";

export const Geometry = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
      strokeLinecap="round"
    >
      <circle cx="6.73" cy="41.24" r=".5" />
      <line x1="13.74" y1="41.24" x2="42.65" y2="41.24" />
      <polygon points="39.34 45.33 38.66 44.6 42.27 41.24 38.66 37.88 39.34 37.15 43.74 41.24 39.34 45.33" />
      <line x1="6.72" y1="34.24" x2="6.72" y2="5.32" />
      <polygon points="10.81 8.64 10.08 9.32 6.72 5.71 3.37 9.32 2.63 8.64 6.72 4.24 10.81 8.64" />
      <line x1="13.73" y1="34.23" x2="34.18" y2="13.78" />
      <polygon points="34.73 19.02 33.73 18.98 33.91 14.05 28.98 14.23 28.94 13.23 34.95 13.02 34.73 19.02" />
    </svg>
  );
};
