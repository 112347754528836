import { useMemo } from "react";
import axios from "axios";
import { useAuth } from "../context/authContext";

export const useAxios = () => {
  const { accessToken } = useAuth();

  // Create an instance of axios
  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    // Add interceptor to include Authorization header if token is present
    instance.interceptors.request.use(
      (config) => {
        if (accessToken) {
          config.headers.Authorization = accessToken;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    return instance;
  }, [accessToken]);

  return axiosInstance;
};
