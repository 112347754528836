// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

// TODO merge with usersReducer
export const initialState = {
  companies: [],
  companyProfile: null,
  member: null,
  loading: false,
  error: null,
};

export const companiesReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_COMPANIES":
      return {
        ...state,
        companies: action.payload,
      };
    case "LOAD_MEMBER":
      return {
        ...state,
        member: action.payload,
      };
    case "LOAD_START":
      return {
        ...state,
        loading: true,
        error: null,
      };
    case "LOAD_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "ADD_MEMBER_TO_COMPANY":
      return {
        ...state,
        companies: state.companies.map((company) =>
          company.id === action.payload.id
            ? { ...company, ...action.payload }
            : company,
        ),
      };
    case "CREATE_COMPANY":
      return {
        ...state,
        companies: [...state.companies, action.payload],
      };
    case "UPDATE_COMPANY":
      return {
        ...state,
        companies: state.companies.map((item) =>
          item.company_id !== action.payload.company_id ? action.payload : item,
        ),
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};
