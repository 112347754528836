// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useEffect } from "react";

import { useScene } from "../../context/sceneContext";
import { useProviders } from "../../context/providersContext";
import { useSelected } from "../Layout/Layout";

import { type } from "../../components/Navigation/Sidebar/Sidebar";

import { List } from "../../components/Scene/List/List";
import { HomeCard } from "../../components/Scene/HomeCard/HomeCard";
import { Alert } from "../../../web-utils/src/UI/Component/React/V1/Alert/Alert";

import styles from "./Scenes.module.css";

function Scenes() {
  const { scenes, getScenes } = useScene();
  const { list, error, filterList } = scenes;
  const { providers, getProviders } = useProviders();
  const { selectedScene, handleSelect } = useSelected();

  useEffect(() => {
    getScenes();
    getProviders();
  }, [getScenes, getProviders]);

  return (
    <>
      {error ? (
        <div className={styles.error}>
          <Alert variant="error" message={<h3>{error}</h3>} />
        </div>
      ) : (
        <>
          {filterList?.length ? (
            <List list={filterList} title="" />
          ) : (
            <>
              {!list?.length ? null : (
                <div className={styles.wrapper}>
                  {type.map((el, index) => (
                    <HomeCard
                      key={index}
                      scenes={list}
                      providers={providers?.providers}
                      title={el}
                      selectedScene={selectedScene}
                      handleSelect={handleSelect}
                    />
                  ))}
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default Scenes;
