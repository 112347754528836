// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { usersReducer, initialState } from "../reducers/usersReducer";
import { useAxios } from "../../web-utils/src/services/axiosInstance";

const REACT_APP_API_URL = window.env && window.env.REACT_APP_API_URL;

const UsersContext = createContext();

export const UsersProvider = ({ children }) => {
  const [users, dispatch] = useReducer(usersReducer, initialState);
  const axiosInstance = useAxios();

  const getUsers = useCallback(async () => {
    dispatch({ type: "LOAD_USER_START" });
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}user/all/`);
      dispatch({ type: "LOAD_USERS", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const getUserProfile = useCallback(async () => {
    dispatch({ type: "LOAD_USER_START" });
    try {
      const response = await axiosInstance.get(`${REACT_APP_API_URL}user/me/`);
      dispatch({ type: "LOAD_USER_PROFILE", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  return (
    <UsersContext.Provider value={{ users, getUsers, getUserProfile }}>
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = () => {
  const context = useContext(UsersContext);
  return context;
};
