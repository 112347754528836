import React from "react";

import styles from "./Tooltip.module.css";

export const Tooltip = ({ tooltipText, position = "top", children }) => {
  return (
    <div className={styles.tooltip}>
      {children}
      <span className={`${styles.tooltipText} ${styles[position]}`}>
        {tooltipText}
      </span>
    </div>
  );
};
