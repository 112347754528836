import React from "react";

import styles from "./FormIcon.module.css";

export const FormIcon = ({ name, children, ...props }) => {
  const { imgSrc, largeImg } = props;
  return (
    <div
      className={`${largeImg ? styles.field_largeImage : styles.field_smallImage}`}
    >
      {children}
      <div className={styles.field_image}>
        {typeof imgSrc === "string" ? (
          <img src={imgSrc} alt={name} width="48px" height="48px" />
        ) : (
          imgSrc
        )}
      </div>
    </div>
  );
};
