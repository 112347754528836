import React, { useState, useEffect } from "react";

import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";

import { withToolTip } from "../Hooks/withToolTip/withToolTip";

import { FormBody } from "../FormBody/FormBody";

import { Input } from "../Input/Input";
import { Textarea } from "../Textarea/Textarea";
import { InputFile } from "../InputFile/InputFile";
import { Select } from "../Select/Select";
import { InputNumber } from "../InputNumber/InputNumber";
import { Range } from "../Range/Range";
import { Toggle } from "../Toggle/Toggle";
import { Icons } from "../Icons/Icons";

import styles from "./FormGroup.module.css";

const Label = withToolTip(FormBody);

export const FormGroup = ({ name, formType, value, ...props }) => {
  const { formGroupStyle = "horizontal", options, tooltipText } = props;
  const { formState } = useFormContext();
  const { errors } = formState;

  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    if (options) {
      const val = options.filter((el) => el.value === Number(value));
      const type = val.map((el) => {
        return el.label;
      });
      setDefaultValue(type[0]);
    }
  }, [options, value]);

  return (
    <>
      <div className={`${styles[formGroupStyle]}`}>
        <Label
          name={name}
          value={value}
          defaultValue={defaultValue}
          tooltipText={tooltipText}
          {...props}
        />
        {(() => {
          switch (formType) {
            case "Input":
              return <Input name={name} value={value} {...props} />;
            case "Textarea":
              return <Textarea name={name} {...props} />;
            case "File":
              return <InputFile name={name} {...props} />;
            case "Select":
              return <Select name={name} {...props} />;
            case "Number":
              return <InputNumber name={name} value={value} {...props} />;
            case "Range":
              return <Range name={name} value={value} {...props} />;
            case "Checkbox":
              return <Toggle name={name} value={value} {...props} />;
            default:
              return null;
          }
        })()}
      </div>
      <div className="error">
        {errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <div className={styles.error_message}>
                <Icons
                  iconSize={16}
                  iconType="error"
                  iconColor={`var(--color-error)`}
                />
                <p>{message}</p>
              </div>
            )}
          />
        )}
      </div>
    </>
  );
};
