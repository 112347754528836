// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { createRoot } from "react-dom/client";

import { OSContextProvider } from "../web-utils/src/context/OSContext";
import { AuthProvider } from "../web-utils/src/context/authContext";
import { ThemeContextProvider } from "./context/themeContext";
import { WebSocketProvider } from "./context/websocketContext/websocketContext";
import { ProvidersProvider } from "./context/providersContext";
import { UsersProvider } from "./context/usersContext";
import { CompaniesProvider } from "./context/companiesContext";
import { StudiosProvider } from "./context/studiosContext";
import { SceneProvider } from "./context/sceneContext";

import App from "./App";

import "./style/app.module.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <OSContextProvider>
      <ThemeContextProvider>
        <AuthProvider>
          <UsersProvider>
            <CompaniesProvider>
              <StudiosProvider>
                <SceneProvider>
                  <ProvidersProvider>
                    <WebSocketProvider>
                      <App />
                    </WebSocketProvider>
                  </ProvidersProvider>
                </SceneProvider>
              </StudiosProvider>
            </CompaniesProvider>
          </UsersProvider>
        </AuthProvider>
      </ThemeContextProvider>
    </OSContextProvider>
  </React.StrictMode>,
);
