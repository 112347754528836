import { Outlet, Navigate } from "react-router";

import { useUsers } from "../context/usersContext";

const ProtectedAdminRoute = () => {
  const { users } = useUsers();
  const { user } = users;
  return user?.isAdmin ? <Outlet /> : <Navigate to="/" replace />;
};

export default ProtectedAdminRoute;
