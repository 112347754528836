// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

import React, { useState } from "react";
import { NavLink } from "react-router";

import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";

import styles from "./DropdownNav.module.css";

export const DropdownNav = ({
  title,
  href,
  isSidebarOpen,
  openOnClick = false,
  children,
  ...props
}) => {
  const [isSubNavOpen, setIsSubNavOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 576px)");

  const handleOpen = () => setIsSubNavOpen(true);
  const handleClose = () => setIsSubNavOpen(false);
  const toggleOpen = () => setIsSubNavOpen((prev) => !prev);
  return (
    <li
      className={styles.subnavItem}
      onMouseEnter={!openOnClick ? handleOpen : undefined}
      onMouseLeave={!openOnClick ? handleClose : undefined}
      onClick={openOnClick ? toggleOpen : undefined}
    >
      <NavLink
        to={href && href}
        className={({ isActive }) =>
          `${styles.dropdown} ${isActive && href ? styles.sidebarActive : ""}`
        }
      >
        <span>
          <Icons {...props} />
        </span>
        {isSidebarOpen || isMobile ? (
          <>
            <span className={styles.title}>{title}</span>
            <Icons
              iconType={`${!isSubNavOpen ? "down" : "up"}`}
              iconSize={14}
            />
          </>
        ) : null}
      </NavLink>
      {isSidebarOpen && isSubNavOpen && (
        <ul className={styles.collapse}>{children}</ul>
      )}
    </li>
  );
};
