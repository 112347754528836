import React from "react";
import { useFormContext } from "react-hook-form";

import styles from "./Textarea.module.css";

export const Textarea = ({ name, ...props }) => {
  const { register } = useFormContext();
  const { rows = 4, cols = 40, placeholder, disabled } = props;
  return (
    <textarea
      id={name}
      {...register(name)}
      className={styles.textarea}
      placeholder={placeholder || null}
      rows={rows}
      cols={cols}
      disabled={disabled}
    />
  );
};
