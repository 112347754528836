import React from "react";

import { Loader } from "../Loader/Loader";
import { FormIcon } from "../FormIcon/FormIcon";

import styles from "./FormBody.module.css";

export const FormBody = ({ name, value, defaultValue, ...props }) => {
  const {
    label,
    noValueShow,
    unit,
    imgSrc,
    isLoading,
    isFieldDirty,
    isSubmitSuccess,
  } = props;

  return (
    <div className="field_body">
      {imgSrc ? (
        <FormIcon name={name} {...props}>
          <label htmlFor={name}>{label}</label>
        </FormIcon>
      ) : (
        <label htmlFor={name}>{label}</label>
      )}

      {isLoading ? (
        <div className={styles.loading_value}>
          <Loader small="true" />
        </div>
      ) : noValueShow ? null : (
        <p
          className={`${isFieldDirty && styles.value_isDirty} ${isSubmitSuccess && styles.value_success}`}
        >
          <>
            {defaultValue || value} <span> {!unit ? "" : ` ${unit}`}</span>
          </>
        </p>
      )}
    </div>
  );
};
