import React from "react";

export const StarStatus = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill={color}
      stroke="0px"
    >
      <path d="M24,10.99c7.72,0,14,5.84,14,13.01s-6.28,13.01-14,13.01-14-5.84-14-13.01,6.28-13.01,14-13.01M24,2.99C11.85,2.99,2,12.4,2,24s9.85,21.01,22,21.01,22-9.41,22-21.01S36.15,2.99,24,2.99h0Z" />
      <path
        d="M24,16c-4.42,0-8,3.58-8,8s3.58,8,8,8,8-3.58,8-8-3.58-8-8-8h0Z"
        fill="#fff"
      />
    </svg>
  );
};
