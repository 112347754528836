import React from "react";
import { Navigate, useLocation } from "react-router";
import { useAuth } from "../context/authContext";

export const ProtectedRoute = ({ children }) => {
  const { accessToken } = useAuth();
  const location = useLocation();

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace={true} />;
  }
  return children;
};
