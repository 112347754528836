// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import styles from "./SizeInBytes.module.css";

export const SizeInBytes = ({ size }) => {
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (
      parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
    );
  };

  const sizeInBytes = formatBytes(size);
  return <p className={styles.small}>{sizeInBytes}</p>;
};
