import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Icons } from "../Icons/Icons";
import { Loader } from "../Loader/Loader";

import styles from "./Toggle.module.css";

export const Toggle = ({ name, value, ...props }) => {
  const { icon, type, size, color, onChange, disabled, isLoading } = props;
  const { control, setValue, watch } = useFormContext();

  useEffect(() => {
    setValue(name, value);
  }, [name, value, setValue]);

  const fieldValue = watch(name);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    setValue(name, isChecked);
    if (onChange) {
      onChange(name, isChecked);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader small="true" />
      ) : (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div
              className={`${!icon ? styles.toggle : styles.toggle_icon} ${
                field.value ? `${styles.on}` : `${styles.off}`
              }`}
            >
              <label htmlFor={name}>
                {icon ? (
                  <span className={styles.toggle_icon_label}>
                    <Icons iconType={type} iconSize={size} iconColor={color} />
                    <span style={{ color }}>{field.value ? "ON" : "OFF"}</span>
                  </span>
                ) : (
                  <span
                    className={`${styles.switch} ${
                      field.value ? `${styles.on}` : `${styles.off}`
                    }`}
                  ></span>
                )}
                <input
                  type="checkbox"
                  className={styles.checkbox}
                  id={field.name}
                  name={field.name}
                  checked={fieldValue || value}
                  onChange={handleChange}
                  disabled={disabled}
                />
              </label>
            </div>
          )}
        />
      )}
    </>
  );
};
