import React from "react";

export const Librairy = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M43.91,16l-1.44,23.17c-.12,1.88-1.68,3.35-3.57,3.35l-30.13-.05c-1.9,0-3.46-1.49-3.55-3.39l-1.13-22.85c-.09-1.77,1.14-3.3,2.82-3.66.24-.05.49-.08.75-.08h32.74c.15,0,.29.01.43.03,1.74.21,3.08,1.69,3.08,3.48Z" />
      <path d="M40.83,12.3v.22c-.14-.02-.28-.03-.43-.03H7.66c-.26,0-.51.03-.75.08v-2.76c0-2.39,1.93-4.32,4.32-4.32h4.3c.71,0,1.4.21,1.99.61l2.13,1.47c.41.28.9.43,1.4.43h15.47c2.38,0,4.31,1.92,4.31,4.3Z" />
    </svg>
  );
};
