// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
export const initialState = {
  list: [],
  filterList: [],
  keywords: [],
  unrealVersions: [],
  sceneIssceneIsLoading: false,
  error: null,
};

export const sceneReducer = (state, action) => {
  switch (action.type) {
    case "LOAD_START":
      return {
        ...state,
        sceneIsLoading: true,
        error: null,
      };
    case "LOAD_ERROR":
      return {
        ...state,
        sceneIsLoading: false,
        error: action.payload,
      };
    case "LOAD_SCENES": {
      return {
        ...state,
        list: action.payload,
        sceneIsLoading: false,
        error: null,
      };
    }
    case "FILTERED_SCENES":
      return {
        ...state,
        filterList: action.payload,
        sceneIsLoading: false,
        error: null,
      };
    case "DELETE_FILTERED_SCENES":
      return {
        ...state,
        filterList: [],
        error: null,
      };
    case "CREATE_SCENE":
      return {
        ...state,
        list: [...state.list, action.payload],
        sceneIsLoading: false,
        error: null,
      };
    case "UPDATE_SCENE":
      return {
        ...state,
        list: state.list.map((item) =>
          item.scene_id === action.payload.scene_id ? action.payload : item,
        ),
        sceneIsLoading: false,
        error: null,
      };
    case "DELETE_SCENE":
      return {
        ...state,
        list: state.list.filter(
          (item) => item.scene_id !== action.payload.scene_id,
        ),
        sceneIsLoading: false,
        error: null,
      };
    case "GET_KEYWORDS":
      return {
        ...state,
        keywords: action.payload,
        sceneIsLoading: false,
        error: null,
      };
    case "ADD_KEYWORD":
      return {
        ...state,
        list: state.list.map((item) =>
          item.scene_id === action.payload.scene_id
            ? { ...item, keywords: [...item.keywords, action.payload?.keyword] }
            : item,
        ),
        sceneIsLoading: false,
        error: null,
      };
    case "GET_UNREAL_VERSIONS":
      return {
        ...state,
        unrealVersions: action.payload,
        sceneIsLoading: false,
        error: null,
      };
    // TODO
    // case "ADD_UNREAL_VERSION":
    //   return {
    //     ...state,
    //     list: state.list.map((item) =>
    //       item.scene_id !== action.payload.scene_id
    //         ? { ...item, name: action.payload.name }
    //         : item,
    //     ),
    //     sceneIsLoading: false,
    //     error: null,
    //   };
    // TODO
    // case "BUY_SCENE":
    //   return {
    //     ...state,
    //     list: state.list.map((item) =>
    //       item.scene_id !== action.payload.scene_id
    //         ? { ...item, purchased_at: action.payload.purchased_at }
    //         : item,
    //     ),
    //     sceneIsLoading: false,
    //     error: null,
    //   };
    default:
      return state;
  }
};
