import React from "react";

export const AddStars = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
      <circle cx="9.09" cy="22.58" r="2" />
      <circle cx="25.86" cy="9.66" r="2" />
      <circle cx="33.54" cy="30.01" r="2" />
      <circle cx="16.38" cy="38.46" r="2" />
    </svg>
  );
};
