import React from "react";

export const ArrowLeft = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="4px"
      stroke={color}
    >
      <polyline points="35 46 13 24 35 2" />
    </svg>
  );
};
