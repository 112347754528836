import React from "react";
import { useFormContext } from "react-hook-form";

import styles from "./Select.module.css";

export const Select = ({ name, ...props }) => {
  const { register } = useFormContext();
  const { options, disabled } = props;
  return (
    <select
      {...register(name)}
      className={styles.custom_select}
      disabled={disabled}
      id={name}
    >
      {options.map((option, i) => (
        <option value={option.value} key={i}>
          {option.label}
        </option>
      ))}
    </select>
  );
};
