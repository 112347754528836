// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

// TODO Merge with usersContext
import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { companiesReducer, initialState } from "../reducers/companiesReducer";
import { useAxios } from "../../web-utils/src/services/axiosInstance";

const REACT_APP_API_URL = window.env && window.env.REACT_APP_API_URL;

const CompaniesContext = createContext();

export const CompaniesProvider = ({ children }) => {
  const [companies, dispatch] = useReducer(companiesReducer, initialState);
  const axiosInstance = useAxios();

  const getCompanies = useCallback(async () => {
    dispatch({ type: "LOAD_START" });
    try {
      const response = await axiosInstance.get(
        `${REACT_APP_API_URL}company/all/`,
      );
      dispatch({ type: "LOAD_COMPANIES", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const getCompanyMember = useCallback(
    async (companyMemberId) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.get(
          `${REACT_APP_API_URL}company/member/${companyMemberId}/`,
        );
        dispatch({ type: "LOAD_MEMBER", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const addMemberToCompany = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}company/add/user/`,
          data,
        );
        dispatch({
          type: "ADD_MEMBER_TO_COMPANY",
          payload: response.data,
        });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const createCompany = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}company/create/`,
          data,
        );
        dispatch({ type: "CREATE_COMPANY", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const updateCompany = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.patch(
          `${REACT_APP_API_URL}company/update/`,
          data,
        );
        dispatch({ type: "UPDATE_COMPANY", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  return (
    <CompaniesContext.Provider
      value={{
        companies,
        getCompanies,
        getCompanyMember,
        addMemberToCompany,
        createCompany,
        updateCompany,
      }}
    >
      {children}
    </CompaniesContext.Provider>
  );
};

export const useCompanies = () => {
  const context = useContext(CompaniesContext);
  return context;
};
