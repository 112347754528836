import React, { createContext, useState, useContext, useEffect } from "react";
import {
  getAccessToken as getStoredAccessToken,
  removeAccessTokens,
  getToken as getStoredToken,
  removeToken,
} from "../utils/utils";

const AuthContext = createContext();

// Global variable to expose the current accessToken
let globalAccessToken = null;

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(getStoredAccessToken() || null);
  const [token, setToken] = useState(getStoredToken() || "");

  useEffect(() => {
    // Keep the globalAccessToken in sync with the current context state
    globalAccessToken = accessToken;

    if (!accessToken) {
      removeToken(); // Clean up tokens if no access token
    }
  }, [accessToken]);

  const login = (accesstoken, token) => {
    setAccessToken(accesstoken);
    setToken(token);
  };

  const logout = () => {
    setAccessToken(null);
    setToken("");
    removeAccessTokens();
    removeToken();
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        token,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook for components to use the AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  return context;
};

// Utility function to get the global accessToken outside of React
export const getAccessTokenGlobal = () => globalAccessToken;
