// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { Outlet, useRouteError } from "react-router";

import { useAuth } from "../../../web-utils/src/context/authContext";

import { RootLayout } from "../../../web-utils/src/UI/Component/React/V1/Layout/RootLayout/RootLayout";
import { Navigation } from "../../components/Navigation/Navigation/Navigation";
import { Footer } from "../../components/Navigation/Footer/Footer";

export const Root = () => {
  const { accessToken } = useAuth();

  return (
    <RootLayout navigation={accessToken && <Navigation />} footer={<Footer />}>
      <Outlet />
    </RootLayout>
  );
};

// TODO add styling
export const Error = () => {
  const error = useRouteError();
  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
