import React from "react";

export const Mapping = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
      <line x1="1.93" y1="1.97" x2="1.93" y2="46.05" />
      <line x1="2.01" y1="45.55" x2="46.1" y2="45.55" />
      <circle cx="9.09" cy="22.58" r="2" />
      <circle cx="25.86" cy="9.66" r="2" />
      <circle cx="33.54" cy="30.01" r="2" />
      <circle cx="16.38" cy="38.46" r="2" />
      <circle cx="42.27" cy="9.39" r="2" />
    </svg>
  );
};
