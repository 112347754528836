import React from "react";

import { Icons } from "../Icons/Icons";

import styles from "./Button.module.css";

export const Button = ({
  type = "button",
  color = "primary",
  size = "sm",
  round = false,
  onClick,
  onDbleClick,
  children,
  ...props
}) => {
  const { disabled } = props;
  return (
    <button
      type={type}
      className={`${styles.mo_sys_button} ${styles[color]} ${styles[size]} ${round && styles.round}`}
      disabled={disabled}
      onClick={onClick}
      onDoubleClick={onDbleClick}
    >
      <Icons {...props} />
      {children}
    </button>
  );
};
