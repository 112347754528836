// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { LinkComponent } from "../../../../web-utils/src/UI/Component/React/V1/LinkComponent/LinkComponent";

export const Footer = () => {
  return (
    <ul>
      <li>
        <LinkComponent href={"https://support.mo-sys.com"} iconType="support">
          Support
        </LinkComponent>
      </li>
    </ul>
  );
};
