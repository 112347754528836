// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";
import { useSelected } from "../../../pages/Layout/Layout";

import { Alert } from "../../../../web-utils/src/UI/Component/React/V1/Alert/Alert";

import { Item } from "../Item/Item";

import styles from "./List.module.css";

export const List = ({ title, list }) => {
  const { selectedScene, handleSelect } = useSelected();
  return (
    <>
      {title && (
        <div className={styles.header}>
          <h2>{title}</h2>
        </div>
      )}
      {!list?.length ? (
        <div className={styles.error}>
          <Alert variant="error" message={<h3>no scene found</h3>} />
        </div>
      ) : (
        <div className={styles.container}>
          {list.map((el) => (
            <Item
              key={el.mosys_id}
              item={el}
              handleSelect={handleSelect}
              selectedScene={selectedScene}
            />
          ))}
        </div>
      )}
    </>
  );
};
