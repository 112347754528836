import React from "react";

export const Success = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M20.01,32.5l17-17s-17,17-17,17Z" strokeLinecap="round" />
      <path d="M20,32.5l-9.01-9s9.01,9,9.01,9Z" strokeLinecap="round" />
    </svg>
  );
};
