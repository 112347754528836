// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useEffect } from "react";
import { Outlet, useOutletContext, useLocation } from "react-router";

import { useStudios } from "../../context/studiosContext";

import { Scroll } from "../../../web-utils/src/UI/Component/React/V1/Scroll/Scroll";
import { Details } from "../../components/Scene/Details/Details";

import styles from "./Layout.module.css";

function Layout() {
  const [selectedScene, setSelectedScene] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const location = useLocation();
  const { dispatch } = useStudios();

  const handleSelect = (item) => {
    setSelectedScene(item);
    setIsDetailsOpen(true);
    dispatch({ type: "CLEAR_ERROR" });
  };

  useEffect(() => {
    setSelectedScene(null);
    setIsDetailsOpen(false);
  }, [location]);

  return (
    <>
      <div className={styles.container}>
        <Scroll height="100%">
          <Outlet
            context={{
              selectedScene,
              handleSelect,
            }}
          />
        </Scroll>
      </div>
      {!isDetailsOpen ? null : <Details item={selectedScene} />}
    </>
  );
}

export default Layout;

export const useSelected = () => {
  return useOutletContext();
};
