// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React from "react";

import { Login } from "../../../web-utils/src/UI/Component/React/V1/Login/Login";
import mosaicLogo from "../../../web-utils/shared/img/MoSaic Logo-02.png";

import styles from "./login.module.css";

const REACT_APP_API_URL = window.env && window.env.REACT_APP_API_URL;

function LoginPage() {
  return (
    <div className={styles.container}>
      <Login
        url={`${REACT_APP_API_URL}token`}
        logo={<img src={mosaicLogo} alt="mosaic" />}
      />
    </div>
  );
}

export default LoginPage;
