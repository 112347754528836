// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState } from "react";

import { useAuth } from "../../../../web-utils/src/context/authContext";
import { useUsers } from "../../../context/usersContext";
import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { Button } from "../../../../web-utils/src/UI/Component/React/V1/Button/Button";
import { withToolTip } from "../../../../web-utils/src/UI/Component/React/V1/Hooks/withToolTip/withToolTip";
import { Avatar } from "../../User/Avatar/Avatar";
import { Icons } from "../../../../web-utils/src/UI/Component/React/V1/Icons/Icons";
import { LinkComponent } from "../../../../web-utils/src/UI/Component/React/V1/LinkComponent/LinkComponent";
import { Sidebar } from "../Sidebar/Sidebar";

import mosaicLogo from "../../../../web-utils/shared/img/MoSaic Logo-02.png";

import cgtrader from "../../../assets/External/cgtrader.png";
import fabUnreal from "../../../assets/External/fabUnreal.png";
import turbosquid from "../../../assets/External/turbosquid.png";
import cuebric from "../../../assets/External/cuebric.png";

import styles from "./Navigation.module.css";

const ButtonComponent = withToolTip(Button);

const externalLink = [
  {
    name: "Cgtrader",
    icon: cgtrader,
    link: "https://www.cgtrader.com/",
  },
  {
    name: "TurboSquid",
    icon: turbosquid,
    link: "https://www.turbosquid.com",
  },
  {
    name: "Fab",
    icon: fabUnreal,
    link: "https://www.fab.com/",
  },
  {
    name: "Cuebric",
    icon: cuebric,
    link: "https://cuebric.com/",
  },
];

export const Navigation = () => {
  const { logout } = useAuth();
  const { users } = useUsers();
  const { userProfile } = users;
  const [userDropdown, setUserDropdown] = useState(false);
  const [linkIsOpen, setIsLinkOpen] = useState(false);
  const isMobile = useMediaQuery("(max-width: 576px)");
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  return (
    <div className={`${styles.nav} ${isMobileOpen ? styles.show : ""}`}>
      <div className={styles.toggler}>
        <Button
          onClick={() => setIsMobileOpen(!isMobileOpen)}
          color="light"
          iconType={!isMobileOpen ? "menu" : "close"}
        />
      </div>
      {isMobile && !isMobileOpen ? null : (
        <>
          <div className={styles.logo}>
            <img src={mosaicLogo} alt="mosaic" />
          </div>
          <ul className={styles.navbar}>
            {isMobile && <Sidebar />}
            <li
              className={styles.dropdown}
              onMouseEnter={() => setIsLinkOpen(true)}
              onMouseLeave={() => setIsLinkOpen(false)}
            >
              <Icons iconType="search" />
              <span className={styles.title}>External search</span>
              <Icons iconType="down" iconSize={12} />
              {linkIsOpen && (
                <div className={styles.dropdownMenu}>
                  <ul>
                    {externalLink.map((item, index) => (
                      <li key={index}>
                        <LinkComponent externalLink href={item.link}>
                          <img
                            src={item.icon}
                            alt={`${item.name} icon`}
                            className={styles.navIcon}
                          />
                          <span className={styles.smallText}>{item.name}</span>
                        </LinkComponent>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
            <li
              className={styles.dropdown}
              onMouseEnter={() => setUserDropdown(true)}
              onMouseLeave={() => setUserDropdown(false)}
            >
              <Icons iconType="user" />
              {isMobile && <span className={styles.title}>User</span>}
              <Icons iconType="down" iconSize={12} />
              {userDropdown && (
                <div className={styles.dropdownMenu}>
                  <div className={styles.dropdownHeader}>
                    <Avatar
                      firstName={userProfile?.first_name}
                      lastName={userProfile?.last_name}
                    />
                    <p>{userProfile?.full_name}</p>
                    <span className={styles.xSmallText}>
                      {userProfile?.email}
                    </span>
                  </div>
                  <div className={styles.logout}>
                    <ButtonComponent
                      color="light"
                      onClick={logout}
                      tooltipText={`Logout`}
                      position="left"
                      iconType="logout"
                      sizing
                    >
                      Log out
                    </ButtonComponent>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </>
      )}
    </div>
  );
};
