import React from "react";
import { Controller, useFormContext } from "react-hook-form";

import styles from "./Input.module.css";

export const Input = ({ name, value, ...props }) => {
  const { control } = useFormContext();
  const { type = "text", placeholder, disabled, setIsFieldDirty } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value, onBlur } }) => (
        <input
          id={name}
          type={type}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
            setIsFieldDirty(true);
          }}
          onBlur={onBlur}
          className={styles.input}
          placeholder={placeholder || null}
          disabled={disabled}
          data-testid="input"
        />
      )}
    />
  );
};
