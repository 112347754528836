// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.

// TODO Merge with usersContext
import React, {
  createContext,
  useReducer,
  useContext,
  useCallback,
} from "react";
import { providersReducer, initialState } from "../reducers/providersReducer";
import { useAxios } from "../../web-utils/src/services/axiosInstance";

const REACT_APP_API_URL = window.env && window.env.REACT_APP_API_URL;

const ProvidersContext = createContext();

export const ProvidersProvider = ({ children }) => {
  const [providers, dispatch] = useReducer(providersReducer, initialState);
  const axiosInstance = useAxios();

  const getProviders = useCallback(async () => {
    dispatch({ type: "LOAD_START" });
    try {
      const response = await axiosInstance.get(
        `${REACT_APP_API_URL}provider/all/`,
      );
      dispatch({ type: "LOAD_PROVIDERS", payload: response.data });
    } catch (error) {
      dispatch({
        type: "LOAD_ERROR",
        payload: error?.response?.data?.detail
          ? error?.response?.data?.detail
          : error?.message,
      });
    }
  }, [axiosInstance]);

  const createProvider = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.post(
          `${REACT_APP_API_URL}provider/`,
          data,
        );
        dispatch({ type: "ADD_PROVIDER", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const refreshProvider = useCallback(
    async (id) => {
      dispatch({ type: "LOAD_START" });
      try {
        await axiosInstance.post(
          `${REACT_APP_API_URL}provider/refresh-provider/${id}`,
        );
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const updateProvider = useCallback(
    async (data) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.patch(
          `${REACT_APP_API_URL}provider/update/`,
          data,
        );
        dispatch({ type: "UPDATE_PROVIDER", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  const deleteProvider = useCallback(
    async (providerId) => {
      dispatch({ type: "LOAD_START" });
      try {
        const response = await axiosInstance.delete(
          `${REACT_APP_API_URL}provider/delete/${providerId}/`,
        );
        dispatch({ type: "DELETE_PROVIDER", payload: response.data });
      } catch (error) {
        dispatch({
          type: "LOAD_ERROR",
          payload: error?.response?.data?.detail
            ? error?.response?.data?.detail
            : error?.message,
        });
      }
    },
    [axiosInstance],
  );

  return (
    <ProvidersContext.Provider
      value={{
        providers,
        getProviders,
        createProvider,
        refreshProvider,
        deleteProvider,
        updateProvider,
      }}
    >
      {children}
    </ProvidersContext.Provider>
  );
};

export const useProviders = () => {
  const context = useContext(ProvidersContext);
  return context;
};
