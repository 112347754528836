import React from 'react';

import { Tooltip } from '../../Tooltip/Tooltip';

// render tootltip if the component as toolipText as props

export const withToolTip = (Component) => {
  function WithToolTip(props) {
    const { tooltipText } = props;
    if (tooltipText)
      return (
        <Tooltip tooltipText={tooltipText} {...props}>
          <Component {...props} />
        </Tooltip>
      );
    return <Component {...props} />;
  }
  WithToolTip.displayName = 'Tooltip';
  return WithToolTip;
};
