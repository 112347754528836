import React from 'react';

export const ControlBack = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
      <polygon
        className="cls-1"
        points="8.86 44.38 44.14 24 8.86 3.62 8.86 44.38"
      />
      <line
        className="cls-1"
        x1="3.86"
        y1="2.75"
        x2="3.86"
        y2="45.25"
      />
    </svg>
  );
};
