import React from "react";

export const LedRing = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M41.8,32.62c-.42-9.65-8.76-15.59-17.5-15.59s-17.09,5.94-17.5,15.59c-.02.37,35.02.37,35,0Z" />
      <rect
        x="37.5"
        y="7.87"
        width="5"
        height="2"
        transform="translate(64.41 -24.04) rotate(114.06)"
      />
      <rect
        x="21.5"
        y="4.61"
        width="5"
        height="2"
        transform="translate(29.62 -18.38) rotate(90)"
      />
      <rect
        x="6.5"
        y="7.37"
        width="5"
        height="2"
        transform="translate(13.17 -3.19) rotate(66.89)"
      />
      <line x1="2" y1="39.78" x2="46" y2="39.78" />
    </svg>
  );
};
