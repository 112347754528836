// Copyright 2024 Mo-Sys Engineering Ltd. All Rights Reserved.
import React, { useState, useCallback, useEffect } from "react";
import { useScene } from "../../../context/sceneContext";

import { Search } from "../../../../web-utils/src/UI/Component/React/V1/Search/Search";

export const SearchBar = () => {
  const [query, setQuery] = useState("");
  const { dispatch, filteredScenes } = useScene();

  const fetchFilteredScenes = useCallback(
    async (searchQuery) => {
      await filteredScenes({
        company_id: 1,
        search_term: searchQuery,
      });
    },
    [filteredScenes],
  );

  useEffect(() => {
    if (query) {
      const timer = setTimeout(() => {
        fetchFilteredScenes(query);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      dispatch({ type: "DELETE_FILTERED_SCENES" });
    }
  }, [query, fetchFilteredScenes, dispatch]);

  return (
    <Search name="" placeholder="Search" query={query} setQuery={setQuery} />
  );
};
