import React from "react";

export const Support = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M44.09,12.42v21.99H8.19v.33c0,1.53,1.31,2.78,2.91,2.78h29.99c.78,0,1.51.36,2.02.98l3.87,4.8.02-8.05V15.21c0-1.54-1.3-2.79-2.91-2.79h0Z" />
      <path d="M40.61,8.47v19.42c0,1.53-1.3,2.77-2.9,2.77H7.88c-.77,0-1.51.36-2.01.98l-3.85,4.78-.02-8.01V8.47c0-1.53,1.3-2.77,2.9-2.77h32.81c1.6,0,2.9,1.24,2.9,2.77Z" />
    </svg>
  );
};
