import React from "react";

export const Close = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 48 48"
      fill="none"
      stroke={color}
      strokeWidth="2px"
    >
      <path d="M12.1,35.77l23.89-23.89-23.89,23.89Z" />
      <path d="M35.93,35.83L12.04,11.94l23.89,23.89Z" />
    </svg>
  );
};
