import React from "react";

export const Threedots = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      stroke={color}
      fill={color}
      viewBox="0 0 16 16"
      height={size}
      width={size}
    >
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"></path>
    </svg>
  );
};
