import React from "react";

import { Icons } from "..//Icons/Icons";

import styles from "./Search.module.css";

export const Search = ({ setQuery, query, name, ...props }) => {
  const { placeholder } = props;
  return (
    <div className={styles.search_container}>
      <label htmlFor={name}></label>
      <span className={styles.icon_input}>
        <Icons iconType="search" iconSize="24px" />
      </span>
      <input
        type="search"
        id={name}
        placeholder={placeholder || null}
        value={query}
        className={styles.search_input}
        onChange={(e) => setQuery(e.target.value)}
      />
    </div>
  );
};
