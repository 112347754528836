import React from "react";

export const Settings = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      fill="none"
      strokeWidth="2px"
      stroke={color}
    >
      <path d="M40.57,22.57v3.15c0,1.12.98,2.08,2.35,2.32l2.62.46c-.58,2.78-1.68,5.37-3.2,7.66l-2.37-1.56c-1.13-.74-2.45-.73-3.22.04l-2.22,2.23c-.8.79-.79,2.16,0,3.31l1.55,2.21c-2.27,1.49-4.84,2.58-7.59,3.15l-.48-2.71c-.24-1.37-1.21-2.35-2.33-2.35h-3.14c-1.09,0-2.04.92-2.31,2.25l-.59,2.84c-2.8-.56-5.41-1.65-7.72-3.17l1.62-2.3c.8-1.14.81-2.52.02-3.31l-2.23-2.23c-.76-.76-2.09-.78-3.22-.04l-2.47,1.63c-1.53-2.31-2.64-4.92-3.2-7.73l2.8-.49c1.38-.24,2.36-1.2,2.36-2.32v-3.15c0-1.09-.93-2.03-2.25-2.31l-2.91-.6c.57-2.8,1.68-5.41,3.21-7.72l2.33,1.63c1.14.81,2.52.81,3.31.02l2.22-2.22c.77-.77.79-2.09.04-3.22l-1.61-2.45c2.33-1.53,4.97-2.63,7.81-3.18l.59,2.86c.27,1.33,1.22,2.25,2.31,2.25h3.14c1.12,0,2.09-.98,2.33-2.35l.47-2.68c2.78.58,5.37,1.7,7.66,3.24l-1.57,2.39c-.75,1.12-.73,2.45.04,3.21l2.22,2.23c.79.79,2.17.78,3.31-.02l2.19-1.53c1.5,2.3,2.58,4.9,3.12,7.68h0s-2.76.57-2.76.57c-1.32.28-2.24,1.22-2.24,2.31Z" />
      <circle cx="24" cy="24" r="9" />
    </svg>
  );
};
